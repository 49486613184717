import { MouseEvent, ReactElement } from 'react';
import { useState } from 'react';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import styles from "./PaswordInput.module.css"

export default function PasswordInput(props: {
  label: string,
  name: string,
  className: string,
  autoComplete: string,
  disabled: boolean,
  value: string,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  onBlur: React.FocusEventHandler<HTMLInputElement>,
}): ReactElement {
  const [isVisible, setVisible] = useState(false);
  const [icon, setIcon] = useState(eyeOff);

  const show = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIcon(eye);
    setVisible(true);
  }

  const hide = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIcon(eyeOff)
    setVisible(false);
  }

  return (
    <div>
      <label htmlFor={props.name} className={props.disabled ? styles.disabled : ""}>{props.label}</label>
      <input
        id={props.name}
        name={props.name}
        value={props.value}
        className={props.className}
        autoComplete={props.autoComplete}
        style={Object.assign({ "paddingRight": "34px" })}
        type={!isVisible ? "password" : "text"}
        disabled={props.disabled}
        onChange={props.onChange}
        onBlur={props.onBlur}
        required />
      {!props.disabled && (
      <span className={styles.PasswordIcon}>
        <Icon icon={icon} size={20} onMouseDown={show} onMouseUp={hide} onMouseLeave={hide} />
      </span>
      )}
    </div>
  );
}
