export function getSecondsSinceEpochFromDate(date: Date): number {
  let date_clone = new Date(date);
  let seconds = date_clone.getTime() / 1000.0;
  return seconds;
}

export function convertSecondsSinceEpochToDateInputString(
  seconds_since_epoch: number
): string {
  var ret = "";
  if (seconds_since_epoch) {
    let date = new Date(
      seconds_since_epoch * 1000 - new Date().getTimezoneOffset() * 60 * 1000
    );
    ret = date.toISOString().substring(0, 10);
  }
  return ret;
}

export function dateMonthToString(date: Date): string {
  switch (date.getMonth()) {
    case 0:
      return "January";
    case 1:
      return "February";
    case 2:
      return "March";
    case 3:
      return "April";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "August";
    case 8:
      return "September";
    case 9:
      return "October";
    case 10:
      return "November";
    case 11:
      return "December";
  }

  return "";
}
