import { useCallback, useEffect, useState } from "react";
import useInput, { UseInputHandler } from "./use-input";
//import { time } from "console";

export interface CohortFormInput {
  isValid: boolean;

  trainingTitle: UseInputHandler;
  topic: UseInputHandler;
  cohortCode: UseInputHandler;
  trainerName: UseInputHandler;
  companyName: UseInputHandler;
  countryCode: UseInputHandler;
  startDate: UseInputHandler;
  endDate: UseInputHandler;
  timeZone: UseInputHandler;
  startTime: UseInputHandler;
  endTime: UseInputHandler;
  expiresAfterDays: UseInputHandler;
  sergeantVersion: UseInputHandler;
  attendantTemplateJsonFilename: UseInputHandler;

  setToDefault: () => void;
}

function nonEmptyValidator(value: string): boolean {
  return value.length !== 0;
}

function alwaysOKValidator(value: string): boolean {
  return true;
}

export default function useCohortForm(
  errorClass: string,
  defaultTrainingTitle: string,
  defaultTopic: string,
  defaultCohortCode: string,
  defaultTrainerName: string,
  defaultCompanyName: string,
  defaultCountryCode: string,
  defaultStartDate: string,
  defaultEndDate: string,
  defaultTimeZone: string,
  defaultStartTime: string,
  defaultEndTime: string,
  defaultExpiresAfterDays: string,
  defaultSergeantVersion: string,
  defaultAttendantTemplateJsonFilename: string
) {
  const trainingTitle = useInput(defaultTrainingTitle, nonEmptyValidator, errorClass);
  const topic = useInput(defaultTopic, nonEmptyValidator, errorClass);
  const cohortCode = useInput(defaultCohortCode, nonEmptyValidator, errorClass);
  const trainerName = useInput(defaultTrainerName, nonEmptyValidator, errorClass);
  const companyName = useInput(defaultCompanyName, alwaysOKValidator, errorClass);
  const countryCode = useInput(defaultCountryCode, alwaysOKValidator, errorClass);
  const startDate = useInput(defaultStartDate, nonEmptyValidator, errorClass);
  const endDate = useInput(defaultEndDate, nonEmptyValidator, errorClass);
  const timeZone = useInput(defaultTimeZone, alwaysOKValidator, errorClass);
  const startTime = useInput(defaultStartTime, alwaysOKValidator, errorClass);
  const endTime =useInput(defaultEndTime, alwaysOKValidator, errorClass);
  const expiresAfterDays = useInput(defaultExpiresAfterDays, nonEmptyValidator, errorClass);
  const sergeantVersion = useInput(defaultSergeantVersion, nonEmptyValidator, errorClass);
  const attendantTemplateJsonFilename = useInput(defaultAttendantTemplateJsonFilename, alwaysOKValidator, errorClass);

  const isFormValid = useCallback(() => {
    return (
      trainingTitle.isValid &&
      topic.isValid &&
      cohortCode.isValid &&
      trainerName.isValid &&
      companyName.isValid &&
      countryCode.isValid &&
      startDate.isValid &&
      endDate.isValid &&
      timeZone.isValid &&
      startTime.isValid &&
      endTime.isValid &&
      expiresAfterDays.isValid &&
      sergeantVersion.isValid &&
      attendantTemplateJsonFilename.isValid
    );
  }, [
    trainingTitle.isValid,
    topic.isValid,
    cohortCode.isValid,
    trainerName.isValid,
    companyName.isValid,
    countryCode.isValid,
    startDate.isValid,
    endDate.isValid,
    timeZone.isValid,
    startTime.isValid,
    endTime.isValid,
    expiresAfterDays.isValid,
    sergeantVersion.isValid,
    attendantTemplateJsonFilename.isValid,
  ]);

  const [isValid, setIsValid] = useState(isFormValid());

  useEffect(() => {
    setIsValid(isFormValid());
  }, [
    trainingTitle.hasError,
    topic.hasError,
    cohortCode.hasError,
    trainerName.hasError,
    companyName.hasError,
    countryCode.hasError,
    startDate.hasError,
    endDate.hasError,
    timeZone.hasError,
    startTime.hasError,
    endTime.hasError,
    expiresAfterDays.hasError,
    sergeantVersion.hasError,
    attendantTemplateJsonFilename.hasError,
    isFormValid,
  ]);

  function setToDefault() {
    trainingTitle.reset(defaultTrainingTitle);
    topic.reset(defaultTopic);
    cohortCode.reset(defaultCohortCode);
    trainerName.reset(defaultTrainerName);
    companyName.reset(defaultCompanyName);
    countryCode.reset(defaultCountryCode);
    startDate.reset(defaultStartDate);
    endDate.reset(defaultEndDate);
    timeZone.reset(defaultTimeZone);
    startTime.reset(defaultStartTime);
    endTime.reset(defaultEndTime);
    expiresAfterDays.reset(defaultExpiresAfterDays);
    sergeantVersion.reset(defaultSergeantVersion);
    attendantTemplateJsonFilename.reset(defaultAttendantTemplateJsonFilename);
  }

  return {
    isValid,
    trainingTitle,
    topic,
    cohortCode: cohortCode,
    trainerName,
    companyName,
    countryCode,
    startDate,
    endDate,
    timeZone,
    startTime,
    endTime,
    expiresAfterDays,
    sergeantVersion,
    attendantTemplateJsonFilename,

    setToDefault,
  };
}
