import { ReactElement, useEffect, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import NotFound from "../../common/NotFound";
import CohortsLayout from "./CohortsLayout";
import CohortFeedbacks from "./CohortFeedbacks";
import CohortData from "./CohortData";
import { IUserInfo } from "../../../messages/IAppInfo";
import CohortMembers from "./CohortMembers";
import useApiState, { api } from "../../../hooks/use-api-state";
import Loading from "../../common/Loading";
import ErrorNotification from "../../common/ErrorNotification";
import { IGetCohortByIdResponse } from "../../../messages/IGetCohortByIdResponse";
import CohortScheduledTasks from "./CohortScheduledTasks";
import CohortMessages from "./CohortMessages";

export default function CohortsManagement(props: {
  userInfo: IUserInfo;
}): ReactElement {
  const [apiState, setApiState] = useApiState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCohort, setSelectedCohort] = useState<IGetCohortByIdResponse | null>(null);

  useEffect(() => {
    const selectedCohortId = searchParams.get("selected-cohort-id");
    if (selectedCohortId !== null) {
      api
        .getCohortById(setApiState, selectedCohortId)
        .then((cohort: IGetCohortByIdResponse) => {
          if (cohort.error===undefined) {
            setSelectedCohort(cohort);
          }
          else {
            unSelectCohort();
          }
        });
    }
    else {
      unSelectCohort();
    }
  }, [props.userInfo, searchParams, setApiState]);


  function unSelectCohort() {
    setSelectedCohort(null);
    let sp = new URLSearchParams(searchParams);
    sp.delete("selected-cohort-id");
    setSearchParams(sp, { replace: true });
  }


  function cohortEditedHandler() {
    let sp = new URLSearchParams(searchParams);
    sp.set("reload-cohort-list", "true");
    setSearchParams(sp, { replace: false });
  }

  return (
    <>
      <CohortsLayout userInfo={props.userInfo}>
        <Loading isLoading={apiState.isLoading}>
          {!!apiState.errorMessage ? (
            <ErrorNotification errorMessage={apiState.errorMessage} />
          ) : (
            !!selectedCohort && (
            <Routes>
              <Route
                index
                element={
                  <CohortData
                    userInfo={props.userInfo}
                    cohort={selectedCohort!}
                    onCohortEdited={cohortEditedHandler}
                  />
                }
              />
              <Route
                path="members"
                element={
                  <CohortMembers
                    userInfo={props.userInfo}
                    cohort={selectedCohort!}
                  />}
              />
              <Route
                path="feedbacks"
                element={<CohortFeedbacks cohort={selectedCohort!} />}
              />
              <Route
                path="scheduled-tasks"
                element={
                  <CohortScheduledTasks
                    cohort={selectedCohort!}
                  />}
              />
              <Route
                path="messages"
                element={
                  <CohortMessages
                    cohort={selectedCohort!}
                  />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
            )
          )}
        </Loading>
      </CohortsLayout>
    </>
  );
}
