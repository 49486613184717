import { MouseEvent, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import styles from "./VerticalLayout.module.css";

export interface IVerticalSplitterState {
    topPanelHeightPx: number,
}

export default function VerticalLayout(props: {
    onSplitterChange?: (state: IVerticalSplitterState) => void,
    topPanel: ReactNode,
    bottomPanel: ReactNode,
    keyForStorage?: string,
}): ReactElement {
    let containerRef = useRef<HTMLDivElement>(null);
    let topPanelRef = useRef<HTMLDivElement>(null);

    const [isSplitterMouseDown, setIsSplitterMouseDown] = useState<boolean>(false);
    const [topPanelHeight, setTopPanelHeight] = useState<string>("470px");

    const movingSplitterClass = isSplitterMouseDown ? styles.moving_splitter_parent : "";
    const movingSplitterPointerEventsClass = isSplitterMouseDown ? styles.moving_splitter_pointer_events : "";

    const storageKey = props.keyForStorage === undefined ? null : `verticalLayout-topPanelHeight-${props.keyForStorage}`;

    function storeTopPanelHeight(height: string) {
        if (storageKey === null) return;

        localStorage.setItem(storageKey, height);
    }

    const loadTopPanelHeight = useCallback(() => {
        if (storageKey === null) return;

        const storedTopPanelHeight = localStorage.getItem(storageKey);
        if (storedTopPanelHeight !== null) {
            setTopPanelHeight(storedTopPanelHeight);
        }
    }, [storageKey]);

    function onSplitterMove(e: MouseEvent<HTMLDivElement>) {
        if (isSplitterMouseDown && !!containerRef.current) {
            const parentBoundingBox = containerRef.current.getBoundingClientRect();
            const topPanelHeight = e.clientY - parentBoundingBox.y;

            setTopPanelHeight(`${topPanelHeight}px`);
            if (props.onSplitterChange) {
                props.onSplitterChange({
                    topPanelHeightPx: topPanelHeight,
                });
            }
        }
    }

    function onSplitterMouseDown(e: MouseEvent<HTMLDivElement>) {
        setIsSplitterMouseDown(true);
    }

    function onSplitterMouseUp(e: MouseEvent<HTMLDivElement>) {
        if (isSplitterMouseDown) {
            storeTopPanelHeight(topPanelHeight);
            setIsSplitterMouseDown(false);
        }
    }

    useEffect(() => {
        loadTopPanelHeight();
    }, [props.keyForStorage, loadTopPanelHeight]);

    useEffect(() => {
        if (props.onSplitterChange && !!topPanelRef.current) {
            props.onSplitterChange({
                topPanelHeightPx: topPanelRef.current.clientHeight,
            });
        }
    }, [topPanelHeight, props])

    return <>
        <div ref={containerRef} className={`${styles.content__placeholder} ${movingSplitterClass}`} onMouseMove={onSplitterMove} onMouseUp={onSplitterMouseUp}>
            <div
                ref={topPanelRef}
                className={`${movingSplitterPointerEventsClass} ${styles.top__panel}`}
                style={{
                    minHeight: topPanelHeight,
                    maxHeight: topPanelHeight,
                }}
            >
                {props.topPanel}
            </div>

            <div
                className={styles.splitter}
                onMouseDown={onSplitterMouseDown}
            />

            <div className={`${movingSplitterPointerEventsClass} ${styles.bottom__panel}`}>
                {props.bottomPanel}
            </div>
        </div>
    </>;
}
