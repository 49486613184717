import { ReactElement, useCallback, useEffect, useState } from "react";
import useApiState, { api } from "../../hooks/use-api-state";
import { IGetUserStatisticsResponse } from "../../messages/IGetUserStatisticsResponse";
import Loading from "../common/Loading";
import ErrorNotification from "../common/ErrorNotification";
import CohortStatistics from "./CohortStatistics";

export default function DashboardStatistics(props: {
    userId: string,
}): ReactElement {
    const [apiState, setApiState] = useApiState(true);
    const [userStatistics, setUserStatistics] = useState<IGetUserStatisticsResponse | null>(null);

    const getMessageItems = useCallback(async () => {
        try {
            setUserStatistics(await api.getUserStatistics(setApiState, props.userId));
        } catch (e) {
        }
    }, [setApiState, props.userId]);

    useEffect(() => {
        getMessageItems();
    }, [getMessageItems]);

    return <>
        <Loading isLoading={apiState.isLoading}>{!!apiState.errorMessage && !apiState.isUnauthenticated ? (
            <ErrorNotification errorMessage={apiState.errorMessage} />
        ) : (
            <>
                {
                    userStatistics !== null && userStatistics.itemList.map((item) => {
                        return <div key={item.cohort_code} className="dashboard__charts">
                            <CohortStatistics item={item} cohortReward={userStatistics.cohortsRewards[item.cohort_code]} />
                        </div>;
                    })
                }
            </>
        )}
        </Loading>
    </>;
}
