import { ReactElement, useEffect, useState } from "react";
import useApiState, { api, IStatusOnlyResponse } from "../../../hooks/use-api-state";
import { IGetCohortByIdResponse } from "../../../messages/IGetCohortByIdResponse";
import Loading from "../../common/Loading";
import styles from "./CohortScheduledTasks.module.css";
import { ICohortScheduledTask, IScheduledTasksResponse } from "../../../messages/IScheduledTasksResponse";

const SEND_EVALFORM_BEFORE_CLASS_END_MINUTES = 30;
const SEND_EVALFORM_REMINDER_AFTER_CLASS_END_MINUTES = 3 * 24 * 60; //After 3 days

export default function CohortScheduledTasks(props: {
  cohort: IGetCohortByIdResponse;
}): ReactElement {
  const [apiState, setApiState] = useApiState(false);
  const [cohortScheduledTasks, setCohortScheduledTasks] = useState<IScheduledTasksResponse | null>(null);

  function setScheduledTask(task: ICohortScheduledTask, enabled: boolean) {
    api
      .setScheduledTasksEnabled(
        setApiState,
        props.cohort.id,
        task.task_id,
        enabled)
      .then((status: IStatusOnlyResponse) => {
        if (status.status === "OK") {
          task.schedule.state = enabled ? "ENABLED" : "DISABLED";
        }
      });
  }

  interface IScheduleTimestamp {
    timezone?: string;
    year?: number;
    month?: number;
    day?: number;
    hour?: number;
    minute?: number;
  }

  function createEvalFormSendTimeFromCohortData(cohort: IGetCohortByIdResponse, delta: number): IScheduleTimestamp {
    var sTimeStamp: IScheduleTimestamp = {};
    const time = cohort.end_time.split(":");
    const hour = parseInt(time[0]);
    const minute = parseInt(time[1]);
    const timestampUTC = cohort.enddate_secs_utc + 60 * (60 * hour + minute + delta);

    sTimeStamp.timezone = cohort.timezone;
    const date = new Date(timestampUTC * 1000);
    sTimeStamp.year = date.getUTCFullYear();
    sTimeStamp.month = date.getUTCMonth() + 1;
    sTimeStamp.day = date.getUTCDate();
    sTimeStamp.hour = date.getUTCHours();
    sTimeStamp.minute = date.getUTCMinutes();
    return sTimeStamp;
  }

  function getTasksOfType(taskType: string): string[] {
    let taskList: string[] = [];
    if (cohortScheduledTasks)
      for (let task of cohortScheduledTasks.scheduled_tasks) {
        if (task.task_type === taskType) {
          taskList.push(task.task_id);
        }
      }
    return taskList;
  }

  function DeleteTask(taskId: string) {
    setCohortScheduledTasks((tasks: IScheduledTasksResponse | null) => {
      if (tasks) {
        let new_tasks = { scheduled_tasks: [...tasks.scheduled_tasks] };
        for (let i = 0; i < new_tasks.scheduled_tasks.length; i++) {
          if (new_tasks.scheduled_tasks[i].task_id === taskId) {
            new_tasks.scheduled_tasks.splice(i, 1);
            break;
          }
        }
        return new_tasks;
      }
      return null;
    });
  }


  function DeleteTaskList(taskList: string[]) {
    for (let taskId of taskList) { // Delete existing tasks of the same type
      api.deleteScheduledTask(setApiState, props.cohort.id, taskId).then((status: IStatusOnlyResponse) => {
        if (status.status === "OK") {
          DeleteTask(taskId);
        }
      });
    }
  }

  async function createScheduleAt(taskType: string, at: IScheduleTimestamp) {
    const existingTasks = getTasksOfType(taskType);
    let status = await api.addScheduledTaskAt(setApiState, props.cohort.id, taskType,
      at.timezone, at.year, at.month, at.day, at.hour, at.minute);
    if (cohortScheduledTasks && status.status === "OK") {
      setCohortScheduledTasks((tasks: IScheduledTasksResponse | null) => {
        if (tasks) {
          let new_tasks = { scheduled_tasks: [...tasks.scheduled_tasks] };
          new_tasks.scheduled_tasks.push(status.added_task);
          return new_tasks;
        }
        return null;
      });
      //cohortScheduledTasks.scheduled_tasks.push(status.added_task);
      DeleteTaskList(existingTasks);
    }
  }


  function createEvalformSchedule() {
    const evalFormAt = createEvalFormSendTimeFromCohortData(props.cohort, -SEND_EVALFORM_BEFORE_CLASS_END_MINUTES);
    createScheduleAt("evalform-email", evalFormAt);
    const evalFormReminderAt = createEvalFormSendTimeFromCohortData(props.cohort, +SEND_EVALFORM_REMINDER_AFTER_CLASS_END_MINUTES);
    createScheduleAt("evalform-reminder-email", evalFormReminderAt);
    //setCohortScheduledTasks(cohortScheduledTasks);
  }


  async function createElearningReminderSchedule() {
    //At 10:00 AM, on day 1 and 15 of the month: cron("0 10 1,15 * *")
    let taskType = "elearning-regular-reminder-email";
    const existingTasks = getTasksOfType(taskType);
    let status = await api.addScheduledTaskCron(setApiState, props.cohort.id, taskType,
      "CET", "0", "10", "1,15", "*", "?", "*");
    if (cohortScheduledTasks && status.status === "OK") {
      cohortScheduledTasks.scheduled_tasks.push(status.added_task);
      DeleteTaskList(existingTasks);
    }
  }


  useEffect(() => {
    api.getCohortScheduledTasksOfCohort(
      setApiState,
      props.cohort.id)
      .then((scheduled_tasks: IScheduledTasksResponse) => {
        setCohortScheduledTasks(scheduled_tasks);
      });
  }, [setApiState, props.cohort.id]);

  return (
    <>
      <Loading isLoading={apiState.isLoading}>
        <div className="cy__form form__evaluations">
          {
            !!cohortScheduledTasks && cohortScheduledTasks.scheduled_tasks.length > 0 ?
              <>
                {cohortScheduledTasks.scheduled_tasks.map((task: ICohortScheduledTask) => {
                  return (
                    <div key={task.task_id} className={styles.task_list_element}>
                      {task.schedule.state === "ENABLED" ?
                        <img
                          src="/icons/OK_40x40_2.png"
                          className={styles.icon}
                          title="The task is enabled."
                          alt=""
                        />
                        :
                        <img
                          src="/icons/error_40x40.png"
                          className={styles.icon}
                          title="The task is disabled."
                          alt=""
                        />
                      }
                      <p className={task.schedule.state === "ENABLED" ? styles.enabled : styles.disabled}>
                        {task.schedule.schedule_expression} {task.schedule.schedule_timezone} &gt; {task.task_type}
                      </p>
                      {task.schedule.state === "ENABLED" ?
                        <button
                          className={`btn primary ${styles.enabledisable_button}`}
                          onClick={() => setScheduledTask(task, false)}>
                          Disable
                        </button>
                        :
                        <button
                          className={`btn primary ${styles.enabledisable_button}`}
                          onClick={() => setScheduledTask(task, true)}>
                          Enable
                        </button>
                      }
                    </div>
                  );
                })}
              </>
              :
              <p>There are no scheduled tasks for this cohort</p>
          }
        </div>

        <div>
          <button className={`btn primary wide ${styles.createNew}`} onClick={createEvalformSchedule} >
            Create eval form schedule
          </button>
        </div>

        <div>
          <button className={`btn primary wide ${styles.createNew}`} onClick={createElearningReminderSchedule} >
            Create E-learning reminder schedule
          </button>
        </div>


      </Loading>
    </>
  );
}
