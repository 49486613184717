import { ReactElement, useCallback, useEffect, useState } from "react";
import Loading from "../../common/Loading";
import useApiState, { api } from "../../../hooks/use-api-state";
import { IGetCohortByIdResponse } from "../../../messages/IGetCohortByIdResponse";
import UserMessage from "./UserMessage";
import { IGetUserMessagesOfCohortResponse } from "../../../messages/IGetUserMessagesOfCohortResponse";
import { IUserMessage } from "../../../model/IUserMessage";
import ErrorNotification from "../../common/ErrorNotification";

export default function CohortMessages(props: {
  cohort: IGetCohortByIdResponse;
}): ReactElement {
  const [apiState, setApiState] = useApiState(false);
  const [cohortMessages, setCohortMessages] = useState<IGetUserMessagesOfCohortResponse | null>(null);

  const sortUserMessages = useCallback((userMessages: IGetUserMessagesOfCohortResponse | null) => {
    if (userMessages === null) {
      return null;
    }

    userMessages.messages.sort((a: IUserMessage, b: IUserMessage) => {
      const aDate = new Date(a.validity_from);
      const bDate = new Date(b.validity_from);
      const aToB = bDate.getTime() - aDate.getTime();
      if (aToB === 0) {
        return a.id < b.id ? -1 : 1;
      } else {
        return aToB;
      }
    });
  }, []);

  const getUserMessagesOfCohort = useCallback(async () => {
    try {
      let resp = await api.getUserMessagesOfCohort(setApiState, props.cohort.id);
      sortUserMessages(resp);
      setCohortMessages(resp);
    } catch (e) {
    }
  }, [props.cohort.id, setApiState, sortUserMessages]);

  useEffect(() => {
    getUserMessagesOfCohort();
  }, [getUserMessagesOfCohort]);

  return (
    <>
      <Loading isLoading={apiState.isLoading}>
        {!!apiState.errorMessage && !apiState.isUnauthenticated ? (
          <ErrorNotification errorMessage={apiState.errorMessage} />
        ) : (
          <>
            <div className="cy__form form__evaluations">
              <div className="flex-column flex-gap-05em">
                {
                  cohortMessages !== null && cohortMessages.messages.length > 0 ?
                    <>
                      {cohortMessages.messages.map((message: IUserMessage) => {
                        return (
                          <div key={message.id}>
                            <UserMessage userMessage={message} />
                          </div>
                        );

                      })}
                    </>
                    :
                    <p>There are no messages for this cohort</p>
                }
              </div>
            </div>
          </>
        )}
      </Loading>
    </>
  );
}
