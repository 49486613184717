import { MutableRefObject, ReactElement, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import useApiState, { ApiStateSetter, api } from "../../../hooks/use-api-state";
import useCohortForm from "../../../hooks/use-cohort-form";
import { hasPermission } from "../../common/ProtectedRoute";
import {
  ICohortIdName,
  IGetCohortListResponse,
} from "../../../messages/IGetCohortListResponse";
import { Month } from "../../../utils/month";
import { getSecondsSinceEpochFromDate } from "../../../utils/time";
import ErrorNotification from "../../common/ErrorNotification";
import Loading from "../../common/Loading";
import ModalOverlay from "../../common/ModalOverlay";
import MonthAndYearSelector from "../../common/MonthAndYearSelector";
import SidebarLayout from "../../common/SidebarLayout";
import CohortForm from "./CohortForm";
import styles from "./CohortsLayout.module.css";
import { IUserInfo } from "../../../messages/IAppInfo";

export default function CohortsLayout(props: {
  userInfo: IUserInfo;
  children: ReactElement;
}): ReactElement {
  const [getCohortListApiState, setGetCohortListApiState] = useApiState(false);

  const cohortListContainerRef = useRef<HTMLUListElement>(null);
  const perfectScrollbarRef = useRef<PerfectScrollbar | null>();

  const [cohortList, setCohortList] = useState<IGetCohortListResponse>({
    cohorts: [],
  });
  const [selectedCohortId, setSelectedCohortId] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<Month | null>(null);
  const [reloadCohortsList, setReloadCohortsList] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  //TODO: can we get these constant values from the Server instead of hard-wiring?
  const cohortFormInput = useCohortForm("cy__err", "", "", "", "", "", "", "", "", "CET", "09:00", "17:00", "93", "28", "");

  const monthAndYearChangeHandler = useCallback(
    function (month: Month, year: number) {
      setSelectedMonth(month);
      setSelectedYear(year);
    }, []);

  useEffect(() => {
    if (selectedYear !== null && selectedMonth !== null) {
      api
        .getCohortList(setGetCohortListApiState, selectedMonth, selectedYear)
        .then((cohortList: IGetCohortListResponse) => {
          setCohortList(cohortList);
        });
    } else {
      setCohortList({
        cohorts: [],
      });
    }
  }, [selectedYear, selectedMonth, reloadCohortsList, setGetCohortListApiState]);

  useEffect(() => {
    let newSelectedCohortId = searchParams.get("selected-cohort-id");
    if (selectedCohortId !== newSelectedCohortId) {
      setSelectedCohortId(newSelectedCohortId);
    }

    if (searchParams.get("reload-cohort-list")) {
      let sp = new URLSearchParams(searchParams);
      sp.delete("reload-cohort-list");
      setSearchParams(sp);

      setReloadCohortsList((value: boolean) => {
        return !value;
      });
    }
  }, [searchParams, setSearchParams, selectedCohortId]);

  const [cohortAddFormOpen, setCohortAddFormOpen] = useState(false);

  function getNavLinkClass(props: { isActive: boolean }): string {
    if (props.isActive) {
      return `${styles.navlink_item} formitem active`;
    } else {
      return `${styles.navlink_item} formitem`;
    }
  }

  function openAddCohort() {
    cohortFormInput.setToDefault();
    setCohortAddFormOpen(true);
  }

  const modalCloseClickHandler = useCallback(
    function () {
      setCohortAddFormOpen(false);
    }, []);

  const cohortAddHandler = useCallback(
    async function (setApiState: ApiStateSetter) {
      let startDateSeconds = getSecondsSinceEpochFromDate(
        new Date(cohortFormInput.startDate.value)
      );
      let endDateSeconds = getSecondsSinceEpochFromDate(
        new Date(cohortFormInput.endDate.value)
      );

      try {
        let addCohortResponse = await api.createCohort(
          setApiState,
          cohortFormInput.trainingTitle.value,
          cohortFormInput.topic.value,
          cohortFormInput.cohortCode.value,
          cohortFormInput.trainerName.value,
          cohortFormInput.companyName.value,
          cohortFormInput.countryCode.value,
          startDateSeconds,
          endDateSeconds,
          parseInt(cohortFormInput.expiresAfterDays.value, 10),
          cohortFormInput.sergeantVersion.value,
          cohortFormInput.attendantTemplateJsonFilename.value,
        );

        if (addCohortResponse !== null) {
          setCohortAddFormOpen(false);

          let sp = new URLSearchParams(searchParams);
          if (sp.has("selected-cohort-id")) {
            sp.delete("selected-cohort-id");
            setSearchParams(sp);
          }

          setReloadCohortsList((value: boolean) => {
            return !value;
          });
        }
      } catch (e: any) { }
    }, [
    cohortFormInput.attendantTemplateJsonFilename.value,
    cohortFormInput.cohortCode.value,
    cohortFormInput.companyName.value,
    cohortFormInput.countryCode.value,
    cohortFormInput.endDate.value,
    cohortFormInput.expiresAfterDays.value,
    cohortFormInput.sergeantVersion.value,
    cohortFormInput.startDate.value,
    cohortFormInput.topic.value,
    cohortFormInput.trainerName.value,
    cohortFormInput.trainingTitle.value,
    searchParams,
    setSearchParams,
  ])

  function createSelectedCohortIdSearchParams(
    cohort_id: string
  ): URLSearchParams {
    let sp = new URLSearchParams(searchParams);

    sp.delete("selected-cohort-id");
    sp.set("selected-cohort-id", cohort_id);

    return sp;
  }

  const destroyPerfectScrollbar = useCallback((perfectScrollbarRef: MutableRefObject<PerfectScrollbar | null | undefined>) => {
    perfectScrollbarRef.current?.destroy();
    perfectScrollbarRef.current = null;
  }, []);

  const renderPerfectScrollbar = useCallback((perfectScrollbarRef: MutableRefObject<PerfectScrollbar | null | undefined>, containerRef: RefObject<HTMLElement>) => {
    if (containerRef.current === null) return;

    destroyPerfectScrollbar(perfectScrollbarRef);

    perfectScrollbarRef.current = createPerfectScrollbarForElement(
      containerRef.current, {
      maxScrollbarLength: 40,
      wheelSpeed: 0.2,
      wheelPropagation: false,
    });
  }, [destroyPerfectScrollbar])

  useEffect(() => {
    renderPerfectScrollbar(perfectScrollbarRef, cohortListContainerRef);

    return () => destroyPerfectScrollbar(perfectScrollbarRef);
  });

  return (
    <>
      {cohortAddFormOpen && (
        <>
          <ModalOverlay
            title="Add new cohort"
            onCloseClick={modalCloseClickHandler}
          >
            <CohortForm
              userInfo={props.userInfo}
              cohortId={null}
              cohortFormInput={cohortFormInput}
              onSubmit={cohortAddHandler}
            >
              <></>
            </CohortForm>
          </ModalOverlay>
        </>
      )}

      <div className="cy__dashboard__forms">
        <SidebarLayout
          keyForStorage="cohorts"
          minimizedTitle="Cohorts"
          sidebarContent={
            <>
              <div className="flex-column">
                <div className={`${styles.filter_form} flex-row`}>
                  <MonthAndYearSelector
                    onChange={monthAndYearChangeHandler}
                    required
                  />
                </div>
                <div className="groups flex-column">
                  <h2>Cohorts</h2>
                  <Loading isLoading={getCohortListApiState.isLoading}>
                    {!!getCohortListApiState.errorMessage ? (
                      <p>
                        <ErrorNotification
                          errorMessage={getCohortListApiState.errorMessage}
                        />
                      </p>
                    ) : (
                      <div>
                        <ul ref={cohortListContainerRef} className="grouplist">
                          {cohortList.cohorts.map((cohort: ICohortIdName) => {
                            return (
                              <li
                                key={cohort.id}
                                className={`group ${selectedCohortId === cohort.id ? "active" : ""
                                  }`}
                              >
                                <NavLink
                                  to={`?${createSelectedCohortIdSearchParams(
                                    cohort.id
                                  ).toString()}`}
                                >
                                  {cohort.code}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </Loading>
                </div>
              </div>
              {!!hasPermission(props.userInfo, [
                { permission: "cohort_create" }
              ]) ? (
                <div id={styles.create_new_cohort_div}>
                  <button className="btn primary wide" onClick={openAddCohort} >
                    Create new cohort
                  </button>
                </div>
              ) : null}
            </>
          }
          mainContent={
            <div className="cy__forms">
              {selectedMonth !== null && selectedYear !== null && (
                <h1>
                  Managing cohorts for {selectedMonth}, {selectedYear}
                </h1>
              )}

              {!!selectedCohortId && (
                <>
                  <nav className="formSelector">
                    <NavLink
                      end
                      className={getNavLinkClass}
                      to={`/admin/cohorts?${searchParams.toString()}`}
                      data-form="groupdata"
                    >
                      Cohort data
                    </NavLink>
                    <NavLink
                      className={getNavLinkClass}
                      to={`/admin/cohorts/members?${searchParams.toString()}`}
                      data-form="certificates"
                    >
                      Members
                    </NavLink>
                    <NavLink
                      className={getNavLinkClass}
                      to={`/admin/cohorts/feedbacks?${searchParams.toString()}`}
                      data-form="evaluations"
                    >
                      Feedbacks
                    </NavLink>
                    <NavLink
                      className={getNavLinkClass}
                      to={`/admin/cohorts/scheduled-tasks?${searchParams.toString()}`}
                      data-form="evaluations"
                    >
                      Scheduled tasks
                    </NavLink>
                    <NavLink
                      className={getNavLinkClass}
                      to={`/admin/cohorts/messages?${searchParams.toString()}`}
                      data-form="evaluations"
                    >
                      Messages
                    </NavLink>
                  </nav>
                  {props.children}
                </>
              )}
            </div>
          }
        />
      </div>
    </>
  );
}
