import { ReactElement, useCallback, useEffect, useState } from "react";
import Select from "react-select"; 
import SidebarLayout from "../common/SidebarLayout";
import useApiState, { api } from "../../hooks/use-api-state";
import { IAppInfo, IUserInfo } from "../../messages/IAppInfo";
import Loading from "../common/Loading";
import ErrorNotification from "../common/ErrorNotification";
import styles from "./Content.module.css";
import { IChapter, IContentData, IContentStructure, IGetUserContentResponse, ILastModuleVisited, IModule, ISlide, SlideVisitedStatisticsDict, VisitedSlides } from "../../messages/IGetUserContentResponse";
import { useSearchParams } from "react-router-dom";
import ContentSlides from "./ContentSlides";
import ContentLessonSelector from "./ContentLessonSelector";

export type LessonIndex = {
  chapterIndex: string,
  moduleIndex: string,
};
type SelectedCourse = {
  courseIndex: number,
  contentData: IContentData,
};
type SelectedLesson = {
  lessonIndex: LessonIndex,
  module: IModule,
  modulePath: string,
  slides: { [slideIndex: string]: ISlide },
  chapter: IChapter,
};

export default function Content(props: {
  appInfo: IAppInfo,
  userInfo: IUserInfo,
}): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();

  const [apiState, setApiState] = useApiState(true);
  const setUnusedApiState = useApiState(true)[1];
  const [userContent, setUserContent] = useState<IGetUserContentResponse | null>(null);
  const [selectedCourse, setSelectedCourse] = useState<SelectedCourse | null>(null);
  const [selectedLesson, setSelectedLesson] = useState<SelectedLesson | null>(null);

  /*
  function onCourseSelected(event: FormEvent<HTMLSelectElement>) {
    let sp = new URLSearchParams();
    sp.set("course-id", event.currentTarget.value);
    setSearchParams(sp, { replace: true });
  }
  */

  function handleChange(selectedOption: any) {
    let sp = new URLSearchParams();
    sp.set("course-id", selectedOption.value);
    setSearchParams(sp, { replace: true });
  }

  const checkIfModuleIsFinishedByTheUser = useCallback((userContent: IGetUserContentResponse, contentKey: string, moduleKey: string) => {
    let ret = false;
    const visitedSlides = userContent.visitedslides;
    if (!!visitedSlides && !!visitedSlides[contentKey]) {
      const visitedModuleStatistics = visitedSlides[contentKey][moduleKey] as SlideVisitedStatisticsDict;
      if (!!visitedModuleStatistics) {
        const visitedEnd = visitedModuleStatistics["_END"];

        if (typeof visitedEnd === 'number') {
          ret = visitedEnd > 0;
        } else if (typeof visitedEnd === 'object') {
          ret = (visitedEnd.visited ?? 0) > 0;
        }
      }
    }

    return ret;
  }, []);

  const calculateModuleFinished = useCallback((userContent: IGetUserContentResponse, contentKey: string, chapterId: string, modules: { [moduleKey: string]: IModule }) => {
    let finishedCount = 0;
    for (let module of Object.values(modules)) {
      const moduleId = module.id ?? "";
      const moduleKey = `${chapterId}/${moduleId}`;
      if (checkIfModuleIsFinishedByTheUser(userContent, contentKey, moduleKey)) {
        finishedCount += 1;
      }
    }
    return finishedCount;
  }, [checkIfModuleIsFinishedByTheUser]);

  const recalculateChapterFinished = useCallback((userContent: IGetUserContentResponse) => {
    for (let content of userContent.content_data) {
      let contentStructure = content.content_structure;
      const cohortCode = contentStructure.content_cohort_code ?? "";
      const contentId = contentStructure.id ?? "";
      const contentKey = `${cohortCode}/${contentId}`;
      if (!!contentStructure && !!contentStructure.chapters) {
        for (let chapter of Object.values(contentStructure.chapters)) {
          const finishedCount = calculateModuleFinished(userContent, contentKey, chapter.id, chapter.modules)
          chapter.done_count = finishedCount;
        }
      }
    }
  }, [calculateModuleFinished]);


  function findCourseIndexByContentKey(userContent: IGetUserContentResponse, lastContentVisited: string) {
    let contentDataIndex = 0;
    for (const contentData of userContent.content_data) {
      const contentKey = `${contentData.content_structure.content_cohort_code}/${contentData.content_structure.id}`;
      if (contentKey === lastContentVisited) {
        return contentDataIndex;
      }

      contentDataIndex += 1;
    }

    return null;
  }

  
  const loadContentData = useCallback(async () => {
    try {
      let userContent = await api.getUserContent(
        setApiState,
        props.userInfo.userId,
      );
      if (!!userContent) {
        recalculateChapterFinished(userContent);
      }

      setUserContent(userContent);
    } catch (e) {
    }
  }, [props.userInfo.userId, setApiState, recalculateChapterFinished]);

  useEffect(() => {
    loadContentData();
  }, [loadContentData]);

  const getChapterAndIndexFromId = useCallback((contentStructure: IContentStructure, chapterId: string): [IChapter, string] | null => {
    if (!contentStructure.chapters) {
      return null;
    }

    for (const [index, chapter] of Object.entries(contentStructure.chapters)) {
      if (chapter.id === chapterId) {
        return [chapter, index];
      }
    }

    return null;
  }, []);

  const getModuleAndIndexFromId = useCallback((chapter: IChapter, moduleId: string): [IModule, string] | null => {
    for (const [index, module] of Object.entries(chapter.modules)) {
      if (module.id === moduleId) {
        return [module, index];
      }
    }

    return null;
  }, []);

  const getPreviousChapter = useCallback((chapterIndex: string): IChapter | null => {
    let chapters = selectedCourse?.contentData?.content_structure?.chapters;
    if (!chapters) {
      return null;
    }

    const previousChapterIndex = (+chapterIndex - 1).toString();
    const previousChapter = chapters[previousChapterIndex];
    if (!previousChapter) {
      return null;
    } else {
      return previousChapter;
    }
  }, [selectedCourse?.contentData?.content_structure?.chapters]);

  const getPreviousLesson = useCallback((chapterIndex: string, moduleIndex: string) => {
    let chapters = selectedCourse?.contentData?.content_structure?.chapters;
    if (!chapters) {
      return null;
    }

    const chapter = chapters[chapterIndex];
    const modules = chapter.modules;
    const previousModuleIndex = (+moduleIndex - 1).toString();
    let previousModule = modules[previousModuleIndex];

    if (!previousModule) {
      let previousChapter = getPreviousChapter(chapterIndex);
      if (previousChapter === null) {
        return null;
      }

      const previousModulesLength = Object.keys(previousChapter.modules).length;
      const previousModule = previousChapter.modules[previousModulesLength.toString()];

      if (!previousModule) {
        return null;
      } else {
        return { chapter: previousChapter, module: previousModule };
      }
    } else {
      return { chapter: chapter, module: previousModule };
    }
  }, [getPreviousChapter, selectedCourse?.contentData?.content_structure?.chapters]);

  function computePreviousLessonLink(lesson: LessonIndex) {
    const previousLesson = getPreviousLesson(lesson.chapterIndex, lesson.moduleIndex);
    if (previousLesson === null) {
      return null;
    }

    let { chapter, module } = previousLesson;
    const sp = new URLSearchParams(searchParams);
    sp.set("chapter-id", chapter.id);
    sp.set("lesson-id", module.id);
    return `?${sp.toString()}`;
  }

  const getNextChapter = useCallback((chapterIndex: string) => {
    let chapters = selectedCourse?.contentData?.content_structure?.chapters;
    if (!chapters) {
      return null;
    }

    const nextChapterIndex = (+chapterIndex + 1).toString();
    const nextChapter = chapters[nextChapterIndex];
    if (!nextChapter) {
      return null;
    } else {
      return nextChapter;
    }
  }, [selectedCourse?.contentData?.content_structure?.chapters]);

  const getNextLesson = useCallback((chapterIndex: string, moduleIndex: string) => {
    let chapters = selectedCourse?.contentData?.content_structure?.chapters;
    if (!chapters) {
      return null;
    }

    const chapter = chapters[chapterIndex];
    const modules = chapter.modules;
    const nextModuleIndex = (+moduleIndex + 1).toString();
    let nextModule = modules[nextModuleIndex];

    if (!nextModule) {
      let nextChapter = getNextChapter(chapterIndex);
      if (nextChapter === null) {
        return null;
      }

      const nextModule = nextChapter.modules["1"];

      if (!nextModule) {
        return null;
      } else {
        return { chapter: nextChapter, module: nextModule };
      }
    } else {
      return { chapter: chapter, module: nextModule };
    }
  }, [getNextChapter, selectedCourse?.contentData?.content_structure?.chapters]);


  function computeNextLessonLink(lesson: LessonIndex) {
    const nextLesson = getNextLesson(lesson.chapterIndex, lesson.moduleIndex);
    if (nextLesson === null) {
      return null;
    }

    let { chapter, module } = nextLesson;
    const sp = new URLSearchParams(searchParams);
    sp.set("chapter-id", chapter.id);
    sp.set("lesson-id", module.id);
    return `?${sp.toString()}`;
  }

  const getLastVisitedLessonId = useCallback((contentData: IContentData, visitedSlides: VisitedSlides) => {
    const contentKey = `${contentData.content_structure.content_cohort_code ?? ""}/${contentData.content_structure.id}`;
    const visitedSlidesOfContent = visitedSlides[contentKey];
    if (!visitedSlidesOfContent) {
      return null;
    }

    const lastModuleVisited = visitedSlidesOfContent["_last_module_visited"] as ILastModuleVisited;
    if (!!lastModuleVisited && !!contentData.content_structure.chapters) {
      for (const [chapterIndex, chapter] of Object.entries(contentData.content_structure.chapters)) {
        for (const [moduleIndex, module] of Object.entries(chapter.modules)) {
          const moduleKey = `${chapter.id}/${module.id}`;
          if (moduleKey === lastModuleVisited.module_key) {
            if (lastModuleVisited.slide_id === "_END") {
              const nextLesson = getNextLesson(chapterIndex, moduleIndex);
              if (!!nextLesson) {
                return { chapterId: nextLesson.chapter.id, moduleId: nextLesson.module.id };
              }
            }

            return { chapterId: chapter.id, moduleId: module.id };
          }
        }
      }
    }

    return null;
  }, [getNextLesson]);

  useEffect(() => {
    if (userContent !== null && userContent.content_data.length > 0) {
      const courseId = searchParams.get("course-id");
      if (courseId === null || +courseId >= userContent.content_data.length || +courseId < 0) {
        // navigate to the last visited course
        let courseIndex = findCourseIndexByContentKey(userContent, userContent.last_content_visited);
        if (courseIndex === null) {
          courseIndex = 0;
        }

        let sp = new URLSearchParams(searchParams);
        sp.set("course-id", courseIndex.toString());
        sp.delete("chapter-id");
        sp.delete("lesson-id");
        setSearchParams(sp, { replace: true });
        setSelectedLesson(null);
      } else {
        let selectedCourseIndex = +courseId;
        setSelectedCourse({
          courseIndex: selectedCourseIndex,
          contentData: userContent.content_data[selectedCourseIndex],
        });
      }
    }
  }, [userContent, searchParams, setSearchParams, setSelectedCourse, getChapterAndIndexFromId, getModuleAndIndexFromId, getLastVisitedLessonId]);

  useEffect(() => {
    if ( userContent !== null && userContent.content_data.length > 0 &&
         selectedCourse !== null)
    {
      // Course has been selected
      let selectedCourseIndex = selectedCourse?.courseIndex;
      const contentData = userContent.content_data[selectedCourseIndex];

      const chapterId = searchParams.get("chapter-id");
      const moduleId = searchParams.get("lesson-id");
      if ( chapterId !== null && moduleId !== null &&
           !!contentData.content_structure.chapters) {
        // there is lesson selected, so it should be loaded
        const chapterAndIndex = getChapterAndIndexFromId(contentData.content_structure, chapterId);
        if (!!chapterAndIndex) {
          const [chapter, chapterIndex]: [IChapter, string] = chapterAndIndex;
          const moduleAndIndex = getModuleAndIndexFromId(chapter, moduleId);
          if (!!moduleAndIndex) {
            const [module, moduleIndex] = moduleAndIndex;
            if (!!module.slides) {
              const moduleDir = module.dir || "";
              const dir = `${contentData.content_structure.dir}/${chapter.dir}/${moduleDir}`;
              const modulePath = contentData.content_root + encodeURIComponent(dir) + "/index.html";

              setSelectedLesson({
                lessonIndex: {
                  chapterIndex: chapterIndex,
                  moduleIndex: moduleIndex,
                },
                module: module,
                modulePath: modulePath,
                slides: module.slides,
                chapter: chapter,
              });

              return;
            }
          }
        }
      } else {
        // navigate to the last visited lesson
        let sp = new URLSearchParams(searchParams);
        const lessonId = getLastVisitedLessonId(userContent.content_data[selectedCourseIndex], userContent.visitedslides);
        if (!!lessonId) {
          sp.set("chapter-id", lessonId.chapterId);
          sp.set("lesson-id", lessonId.moduleId);
        } else {
          sp.delete("chapter-id");
          sp.delete("lesson-id");
        }
        setSearchParams(sp, { replace: true });
        setSelectedLesson(null);

        return;
      }

      let sp = new URLSearchParams(searchParams);
      sp.delete("chapter-id");
      sp.delete("lesson-id");
      setSearchParams(sp, { replace: true });
      setSelectedLesson(null);
    }
  }, [selectedCourse]);

  async function slideVisitedHandler(slideId: string) {
    if (!selectedCourse || !selectedLesson) {
      return;
    }

    const cohortId = (selectedCourse.contentData.content_structure.content_cohort_code ?? "").replaceAll("/", "_");
    const contentId = selectedCourse.contentData.content_structure.id.replaceAll("/", "_");
    const chapterId = selectedLesson.chapter.id.replaceAll("/", "_");
    const moduleId = selectedLesson?.module.id.replaceAll("/", "_");

    if (contentId) {
      try {
        const resp = await api.markSlideVisited(
          setUnusedApiState,
          cohortId,
          contentId,
          chapterId,
          moduleId,
          slideId,
        );
        if (resp.status === "OK") {
          const contentKey = cohortId + "/" + contentId
          const moduleKey = chapterId + "/" + moduleId;
          setUserContent((prev: IGetUserContentResponse | null) => {
            if (!prev) {
              return null;
            }

            let newState = JSON.parse(JSON.stringify(prev)) as IGetUserContentResponse;

            if (!newState.visitedslides[contentKey]) {
              newState.visitedslides[contentKey] = {}
            }
            if (!newState.visitedslides[contentKey][moduleKey]) {
              newState.visitedslides[contentKey][moduleKey] = {}
            }

            let slideVisitedStatisticsDict = newState.visitedslides[contentKey][moduleKey] as SlideVisitedStatisticsDict;
            slideVisitedStatisticsDict[slideId] = 1; // Non-zero just to indicate accomplishment

            // Mark last visited slide in local structure (REST API already updated the DB / persistence)
            newState.visitedslides[contentKey]["_last_module_visited"] = { "module_key": moduleKey, "slide_id": slideId };

            recalculateChapterFinished(newState);

            return newState;
          });
        }
      } catch (e: Response | any) {
      }
    }
  }

  return <>
    <Loading isLoading={apiState.isLoading}>
      {!!apiState.errorMessage && !apiState.isUnauthenticated ? (
        <ErrorNotification errorMessage={apiState.errorMessage} />
      ) : (
        <>
          {userContent !== null &&
            <SidebarLayout
              keyForStorage="content"
              sidebarClass="sidebar container"
              minimizedTitle="Lessons"
              sidebarContent={
                <div className="flex-column">
                  {
                    userContent.content_data.length > 1 &&
                    <>
                      <h3 className={styles.course__title_selector_label}>Select a course to start</h3>
                      <Select
                        className={`courseSelector`}
                        classNamePrefix="courseSelector"
                        options={userContent.content_data.map((content: IContentData, index) => {return {"label": content.content_structure.title, "value": index};})}
                        onChange={handleChange}
                        defaultValue={{"label": userContent.content_data[0].content_structure.title, "value": 0}}
                        /*menuIsOpen={true}*/
                      />
                      {/* 
                      <div className={styles.courseSelector}>
                        <select
                          autoComplete="off"
                          name="course"
                          onChange={onCourseSelected}
                          value={selectedCourse?.courseIndex ?? 0}
                        >
                          {
                            userContent.content_data.map((content: IContentData, index) => {
                              if (!!content.content_root && !!content.content_structure) {
                                return <option key={index} value={`${index}`} title={content.content_structure.source}>
                                  {content.content_structure.title}
                                </option>;
                              } else {
                                return <></>;
                              }
                            })
                          }
                        </select>
                      </div>
                      */}
                    </>
                  }
                  {
                    userContent.content_data.length === 1 &&
                    <>
                      <h3 className={styles.course__title}>
                        {userContent.content_data[0].content_structure.title}
                      </h3>
                    </>
                  }
                  {
                    userContent.content_data.length === 0 &&
                    <>
                      <h3 className={styles.course__title}>You don't have a course</h3>
                    </>
                  }
                  {!!selectedCourse &&
                    <>
                      {selectedCourse.contentData.content_structure.manual_path &&
                        <div className={styles.resources}>
                          {
                            selectedCourse.contentData.content_structure.manual_watermarking ? <>
                              <p>Your course manual is being prepared. Please check back in a couple of minutes.</p>
                            </> : <>
                              <a href={selectedCourse.contentData.content_structure.manual_path} download>
                                <img src="/static/img/files.svg" alt="Files icon" />
                                Download the course manual in PDF.
                              </a>
                            </>
                          }
                        </div>
                      }

                      <ContentLessonSelector
                        selectedLesson={selectedLesson?.lessonIndex ?? null}
                        courseData={selectedCourse.contentData}
                        visitedSlides={userContent.visitedslides}
                      />
                    </>
                  }
                </div>
              }
              mainContent={
                <>
                  {
                    !!selectedCourse &&
                      selectedCourse.courseIndex >= 0 &&
                      selectedCourse.courseIndex < selectedCourse.contentData.content_root.length &&
                      !!selectedLesson &&
                      !!selectedLesson
                      ? <>
                        <ContentSlides
                          appInfo={props.appInfo}
                          userId={props.userInfo.userId}
                          courseData={selectedCourse.contentData}
                          cohortCode={selectedCourse.contentData.content_structure.cohort_code}
                          lmoduleCode={selectedCourse.contentData.content_structure.lmodule_code}
                          vms={selectedCourse.contentData.content_structure.vms}
                          module={selectedLesson.module}
                          modulePath={selectedLesson.modulePath}
                          slides={selectedLesson.slides}
                          previousLessonLink={computePreviousLessonLink(selectedLesson.lessonIndex)}
                          nextLessonLink={computeNextLessonLink(selectedLesson.lessonIndex)}
                          contentStructureId={selectedCourse.contentData.content_structure.id}
                          chapterId={selectedLesson.chapter.id}
                          onSlideVisited={slideVisitedHandler}
                        />
                      </> : <>
                        <div className={styles.content__placeholder}>
                          <div className={styles.text}>Pick a<br />lesson to<br /><b>start</b></div>
                          <img className={styles.svg__child} src="/static/img/child.svg" alt="Child svg" style={{ zIndex: -1 }} />
                          <img className={styles.svg__cloud1} src="/static/img/cloud1.svg" alt="Cloud svg1" style={{ zIndex: -1 }} />
                          <img className={styles.svg__cloud2} src="/static/img/cloud2.svg" alt="Cloud svg2" style={{ zIndex: -1 }} />
                          <img className={styles.svg__circle} src="/static/img/circle.svg" alt="Circle svg" style={{ zIndex: -1 }} />
                        </div>
                      </>
                  }
                </>
              }
            />
          }
        </>
      )}
    </Loading >
  </>;
}
