export enum Month {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
}

export function stringToMonth(month: string): Month | null {
  let ret = null;

  switch (month.toLowerCase()) {
    case "jan":
    case "january": {
      ret = Month.January;
      break;
    }

    case "feb":
    case "february": {
      ret = Month.February;
      break;
    }

    case "mar":
    case "march": {
      ret = Month.March;
      break;
    }

    case "apr":
    case "april": {
      ret = Month.April;
      break;
    }

    case "may": {
      ret = Month.May;
      break;
    }

    case "jun":
    case "june": {
      ret = Month.June;
      break;
    }

    case "jul":
    case "july": {
      ret = Month.July;
      break;
    }

    case "aug":
    case "august": {
      ret = Month.August;
      break;
    }

    case "sep":
    case "september": {
      ret = Month.September;
      break;
    }

    case "oct":
    case "october": {
      ret = Month.October;
      break;
    }

    case "nov":
    case "november": {
      ret = Month.November;
      break;
    }

    case "dec":
    case "december": {
      ret = Month.December;
      break;
    }
  }
  return ret;
}

export function numberToMonth(month: number): Month {
  switch (month) {
    case 1:
      return Month.January;
    case 2:
      return Month.February;
    case 3:
      return Month.March;
    case 4:
      return Month.April;
    case 5:
      return Month.May;
    case 6:
      return Month.June;
    case 7:
      return Month.July;
    case 8:
      return Month.August;
    case 9:
      return Month.September;
    case 10:
      return Month.October;
    case 11:
      return Month.November;
    case 12:
      return Month.December;
  }
  return Month.January;
}


export function monthToNumber(month: Month): number {
  switch (month) {
    case Month.January:
      return 1;
    case Month.February:
      return 2;
    case Month.March:
      return 3;
    case Month.April:
      return 4;
    case Month.May:
      return 5;
    case Month.June:
      return 6;
    case Month.July:
      return 7;
    case Month.August:
      return 8;
    case Month.September:
      return 9;
    case Month.October:
      return 10;
    case Month.November:
      return 11;
    case Month.December:
      return 12;
  }
}
