import { ReactElement } from "react";
import styles from "./UserMessage.module.css";
import { IUserMessage } from "../../../model/IUserMessage";

export default function UserMessage(props: {
    userMessage: IUserMessage;
}): ReactElement {
    if (props.userMessage.type === "inline") {
        return (
            <>
                <div className={`flex-column flex-gap-05em ${styles.card}`}>
                    <div><b>Validity from:</b> {props.userMessage.validity_from}</div>

                    <div className="divider"></div>

                    <div><b>{props.userMessage.payload.subject}</b></div>
                    <div>{props.userMessage.payload.content}</div>
                    {
                        props.userMessage.payload.link &&
                        <div><a href={props.userMessage.payload.link}>{props.userMessage.payload.link}</a></div>
                    }
                </div>
            </>
        );
    } else {
        return (
            <>
                <div>Unknown message type</div>
            </>
        );
    }
}