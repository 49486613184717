import { ReactElement } from "react";

export default function ErrorNotification(props: { errorMessage: string | null }): ReactElement {
	return (
		<>
			{props.errorMessage == null
				?
				<></>
				:
				<p className="cy__err">{props.errorMessage}</p>
			}
		</>
	)
}

export function SuccessNotification(props: { message: string | null }): ReactElement {
	return (
		<>
			{props.message == null
				?
				<></>
				:
				<p className="cy__succ">{props.message}</p>
			}
		</>
	)
}

export function Notification(props: { message: string | null }): ReactElement {
	return (
		<>
			{props.message == null
				?
				<></>
				:
				<p className="cy__notification">{props.message}</p>
			}
		</>
	)
}