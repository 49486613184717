import { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import { IUserInfo } from "../../messages/IAppInfo";
import { hasPermission,hasAnyPermission } from "../common/ProtectedRoute";


export default function NavigationItems(props: {
  userInfo: IUserInfo | null;
}): ReactElement {
  function getNavLinkClass(props: { isActive: boolean }): string {
    if (props.isActive) {
      return "active_page active";
    } else {
      return "active_page";
    }
  }

  if (props.userInfo !== null) {
    return (
      <>
        <li>
          {hasPermission(props.userInfo, [
            { permission: "invitation_read_of_allowed_partner_organizations" }
          ]) && (
              <NavLink className={getNavLinkClass} to="/admin/invitations">
                Invitations
              </NavLink>
            )}
        </li>
        <li>
          {hasPermission(props.userInfo, [
            { permission: "partner_organization_update" }
          ]) && (
              <NavLink className={getNavLinkClass} to="/admin/access-tokens">
                Access Tokens
              </NavLink>
            )}
        </li>
        <li>
          {hasPermission(props.userInfo, [
            { permission: "cohort_write" }
          ]) && (
              <NavLink className={getNavLinkClass} to="/admin/billing-report">
                Billing report
              </NavLink>
            )}
        </li>
        <li>
          {hasAnyPermission(props.userInfo, [
            { permission: "cohort_read" },
            { permission: "cohort_write" }
          ]) && (
              <NavLink className={getNavLinkClass} to="/admin/cohorts">
                Cohorts
              </NavLink>
            )}
        </li>
        <li>
          <NavLink className={getNavLinkClass} to="/admin/password">
            Password change
          </NavLink>
        </li>
      </>
    );
  } else {
    return <></>;
  }
}
