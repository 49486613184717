import { ReactElement, useCallback, useEffect, useState } from "react";
import styles from "./ContentLessonSelector.module.css";
import { IChapter, IContentData, IModule, SlideVisitedStatisticsDict, VisitedSlides } from "../../messages/IGetUserContentResponse";
import { useSearchParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { LessonIndex } from "./Content";

type ChaptersShown = { [chapterIndex: string]: boolean };

export default function ContentLessonSelector(props: {
  selectedLesson: LessonIndex | null,
  courseData: IContentData,
  visitedSlides: VisitedSlides,
}): ReactElement {
  const searchParams = useSearchParams()[0];

  const [structureId, setStructureId] = useState<string>(props.courseData.content_structure.id);
  const [chaptersShown, setChaptersShown] = useState<ChaptersShown>({});
  const [areLessonsCollapsed, setAreLessonsCollapsed] = useState<boolean>(false);

  const checkIfModuleIsFinishedByTheUser = useCallback((contentKey: string, moduleKey: string) => {
    let ret = false;
    if (!!props.visitedSlides[contentKey]) {
      const visitedModuleStatistics = props.visitedSlides[contentKey][moduleKey] as SlideVisitedStatisticsDict;
      if (!!visitedModuleStatistics) {
        const visitedEnd = visitedModuleStatistics["_END"];

        if (typeof visitedEnd === 'number') {
          ret = visitedEnd > 0;
        } else if (typeof visitedEnd === 'object') {
          ret = (visitedEnd.visited ?? 0) > 0;
        }
      }
    }

    return ret;
  }, [props.visitedSlides]);

  const intializeChaptersShown = useCallback((shown: boolean) => {
    let chaptersShown: ChaptersShown = {};
    if (!!props.courseData.content_structure && !!props.courseData.content_structure.chapters) {
      for (const chapterIndex of Object.keys(props.courseData.content_structure.chapters)) {
        chaptersShown[chapterIndex] = shown;
      }
    }
    setChaptersShown(chaptersShown);
  }, [props.courseData.content_structure]);

  function toggleAll() {
    if (areLessonsCollapsed) {
      setAreLessonsCollapsed(false);
      intializeChaptersShown(true);
    } else {
      setAreLessonsCollapsed(true);
      intializeChaptersShown(false);
    }
  }

  function toggleChapterShown(chapterIndex: string) {
    setChaptersShown((prev: ChaptersShown) => {
      let newState = { ...prev };

      let newShown = !prev[chapterIndex];
      newState[chapterIndex] = newShown;

      if (newShown) {
        setAreLessonsCollapsed(false);
      } else {
        let allHidden = true;
        for (const chapterIndex of Object.keys(newState)) {
          if (newState[chapterIndex]) {
            allHidden = false;
            break;
          }
        }
        if (allHidden) {
          setAreLessonsCollapsed(true);
        }
      }
      return newState;
    });
  }


  function getTogglerIconClass(shown: boolean) {
    if (shown) {
      return "fa-chevron-up";
    } else {
      return "fa-chevron-down";
    }
  }

  function isChapterShown(chapterIndex: string) {
    return !!chaptersShown[chapterIndex];
  }

  function getLessonLiClassName(chapterIndex: string, moduleIndex: string) {
    let active = props.selectedLesson?.chapterIndex === chapterIndex && props.selectedLesson?.moduleIndex === moduleIndex;
    let contentStructure = props.courseData.content_structure;

    if (!contentStructure.chapters) {
      return "";
    }

    let chapter = contentStructure.chapters[chapterIndex];
    if (!chapter) {
      return "";
    }

    let module = chapter.modules[moduleIndex];
    if (!module) {
      return "";
    }

    const highlightClass = !!module.highlight ? styles.highlight : "";

    const cohortCode = contentStructure.content_cohort_code ?? "";
    const contentId = contentStructure.id ?? "";
    const contentKey = `${cohortCode}/${contentId}`;
    const chapterId = chapter.id ?? "";
    const moduleId = module.id ?? "";
    const moduleKey = `${chapterId}/${moduleId}`;

    const done = checkIfModuleIsFinishedByTheUser(contentKey, moduleKey);
    return `${done ? styles.done : ""} ${highlightClass} ${active ? styles.active : ""}`;
    /*
    if (checkIfModuleIsFinishedByTheUser(contentKey, moduleKey)) {
      return `${styles.done} ${highlightClass} ${active? styles.active : ""}`;
    } else {
      return `${highlightClass} ${active? styles.active : ""}`;
    }
    */
  }

  function getChapterClass(chapterIndex: string) {
    const shownClass = isChapterShown(chapterIndex) && !areLessonsCollapsed ? styles.shown : "";

    let highlightClass = "";
    const contentStructure = props.courseData.content_structure;
    if (!!contentStructure.chapters) {
      let chapter = contentStructure.chapters[chapterIndex];
      if (!!chapter && !!chapter.highlight) {
        highlightClass = styles.highlight;
      }
    }

    return `${styles.course} ${shownClass} ${highlightClass}`
  }

  function getLessonLink(chapterId: string, moduleId: string) {
    let sp = new URLSearchParams(searchParams);
    sp.set("chapter-id", chapterId);
    sp.set("lesson-id", moduleId);
    return `?${sp.toString()}`;
  }

  useEffect(() => {
    setChaptersShown((prev: ChaptersShown) => {
      if (props.selectedLesson === null) {
        return prev;
      }

      let newState = { ...prev };
      newState[props.selectedLesson.chapterIndex] = true;

      setAreLessonsCollapsed(false);

      return newState;
    });
  }, [props.selectedLesson]);

  useEffect(() => {
    if (structureId !== props.courseData.content_structure.id) {
      setStructureId(props.courseData.content_structure.id);
      intializeChaptersShown(true);
    }
  }, [intializeChaptersShown, props.courseData.content_structure.id, structureId, setStructureId]);

  return <>
    <div className={styles.content__wrapper}>
      {/*
      {props.courseData.content_structure.manual_path !== null &&
        <div className={styles.resources}>
          {
            props.courseData.content_structure.manual_watermarking ? <>
              <p>Your course manual is being prepared. Please check back in a couple of minutes.</p>
            </> : <>
              <a href={props.courseData.content_structure.manual_path} download>
                <img src="/static/img/files.svg" alt="Files icon" />
                Download the course manual in PDF.
              </a>
            </>
          }
        </div>
      }
      */}

      {
        !!props.courseData.content_structure.chapters && <>
          <div className={styles.courses}>
            <div style={{ display: "flex" }}>
              <div style={{ float: "left", flex: "1" }}>
                {
                  props.courseData.content_structure.unit === 0 ? <>
                    <h2>Lessons</h2>
                  </> : <>
                    <h2>Lessons <span className={styles.unit} title={props.courseData.content_structure.next_unit_change} style={{ cursor: "help" }}>
                      (Unit {props.courseData.content_structure.unit})
                    </span></h2>
                  </>
                }
              </div>
              <div
                className={styles.lessons_chevron} 
                onClick={toggleAll}
              >
                <i className={`fas ${areLessonsCollapsed ? "fa-chevron-down" : "fa-chevron-up"}`}></i>
              </div>
            </div>

            <div className={styles.list}>
              {
                Object.entries(props.courseData.content_structure.chapters).map(([chapterIndex, chapter]: [string, IChapter]) => {
                  return <div
                    key={chapterIndex}
                    className={getChapterClass(chapterIndex)}
                  >
                    <div
                      className={styles.course__head}
                      onClick={() => toggleChapterShown(chapterIndex)}>
                      <p className={styles.name}>{chapter.title}</p>
                      <div className={styles.course__count}>
                        <p>
                          <span className={
                            !chapter.done_count
                              ?
                              styles.all
                              :
                              `${styles.done} ${styles.progress}`
                          }>
                            {chapter.done_count ?? 0}
                          </span>
                          /
                          <span className={
                            !chapter.done_count
                              ?
                              styles.all
                              :
                              (Object.keys(chapter.modules).length === chapter.done_count
                                ?
                                `${styles.done} ${styles.progress}`
                                :
                                ""
                              )
                          }>
                            {Object.keys(chapter.modules).length ?? 0}
                          </span>
                        </p>
                        <i className={
                          `fas chevron ${getTogglerIconClass(isChapterShown(chapterIndex))} ${styles.chevron}`}>
                        </i>
                      </div>
                    </div>
                    <ul className={styles.course__lessons}>
                      {Object.entries(chapter.modules).map(([moduleIndex, module]: [string, IModule]) => {
                        return <li key={moduleIndex} className={getLessonLiClassName(chapterIndex, moduleIndex)}>
                          <NavLink to={getLessonLink(chapter.id, module.id)}>
                            {module.title}
                          </NavLink>
                        </li>;
                      })}
                    </ul>
                  </div>;
                })
              }
            </div>
          </div>
        </>
      }
    </div>
  </>;
}
