import { FormEvent, ReactElement, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useApiState, { api } from "../../hooks/use-api-state";
import useInput from "../../hooks/use-input";
import PasswordInput from "../common/PaswordInput";
import ErrorNotification, { SuccessNotification } from "../common/ErrorNotification";
import Loading from "../common/Loading";

function PasswordResetTokenPage(props: {
  username: string;
  passwordResetToken: string;
  initialErrorMessage: string;
}): ReactElement {
  const [apiState, setApiState] = useApiState(false);
  const [succMessage, setSuccMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const passwordInput = useInput(
    "",
    (value: string): boolean => {
      return value.length >= 9;
    },
    "cy__err"
  );
  const passwordRepeatInput = useInput(
    "",
    (value: string): boolean => {
      return passwordInput.value === value;
    },
    "cy__err"
  );


  const passwordRepeatErrorClass = passwordRepeatInput.hasError
    ? "cy__err"
    : "";
  const passwordRepeatErrorMessage = passwordRepeatInput.hasError
    ? "The passwords do not match"
    : "";


  async function passwordBlurHandler(
    event:
      | React.FormEvent<HTMLInputElement | HTMLSelectElement>
      | React.FocusEvent<HTMLTextAreaElement>
      | React.FormEvent<HTMLSelectElement>
  ) {
    apiState.errorMessage = "";
    api.checkPassword(
      passwordInput.value,
      { passwordResetToken: props.passwordResetToken, setErrorMessage: setPasswordErrorMessage }
    ).then((ret) => { });
    passwordInput.inputBlurHandler(event);
  }


  async function submitHandler(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const ret = await api.passwordReset(
      setApiState,
      passwordInput.value,
      props.passwordResetToken,
    );
    if (ret !== null) {
      if (ret.status === "OK") {
        setPasswordErrorMessage("");
        setSuccMessage("The password has been changed.");
      } else if (ret.status === "WEAK") {
        setPasswordErrorMessage(ret.message);
      }
    }
  }

  if (props.initialErrorMessage !== "") {
    apiState.errorMessage = props.initialErrorMessage;
  }

  return (
    <>
      <div className="cy__login">
        <div className="login__flex">
          <h1>Reset your password</h1>
          <form onSubmit={submitHandler}>
            <div>
              <input
                name="username"
                type="hidden"
                autoComplete="username"
                value={props.username}
              />
              <PasswordInput
                label="Pick a new password"
                name="password"
                disabled={props.initialErrorMessage !== ""}
                className={`${passwordErrorMessage ? 'cy__err' : ''}`}
                autoComplete="new-password"
                value={passwordInput.value}
                onChange={passwordInput.valueChangeHandler}
                onBlur={passwordBlurHandler}
              />
              {!!passwordErrorMessage && (
                <p className="cy__err">{passwordErrorMessage}</p>
              )}
            </div>

            <div>
              <PasswordInput
                label="Repeat your new password"
                name="password-repeated"
                disabled={props.initialErrorMessage !== ""}
                className={`${passwordRepeatErrorClass}`}
                autoComplete="new-password"
                value={passwordRepeatInput.value}
                onChange={passwordRepeatInput.valueChangeHandler}
                onBlur={passwordRepeatInput.inputBlurHandler}
              />
              {!passwordErrorMessage && !!passwordRepeatErrorMessage && (
                <p className="cy__err">{passwordRepeatErrorMessage}</p>
              )}
            </div>

            {!!apiState.errorMessage ? (
              <div>
                <br />
                <ErrorNotification errorMessage={apiState.errorMessage} />
              </div>
            ) : (
              <>
                {!!succMessage && (
                  <div>
                    <SuccessNotification message={succMessage} />
                  </div>
                )}
              </>
            )}

            <div>
              <button
                type="submit"
                className="btn wide primary"
                disabled={
                  !(props.initialErrorMessage === "" &&
                    passwordErrorMessage === "" &&
                    passwordRepeatInput.isValid)
                }
              >
                <Loading isLoading={apiState.isLoading}>Change password</Loading>
              </button>
            </div>
          </form>
          <br />
          <button
            className="btn wide secondary"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = '/';
            }}>
            Back
          </button>
        </div>
      </div>
    </>
  );
}


export default function PasswordReset(): ReactElement {
  const [initialErrorMessage, setInitialErrorMessage] = useState("?")
  const [username, setUsername] = useState("?")
  const [searchParams] = useSearchParams();
  let passwordResetToken = searchParams.get("password-reset-token") ?? "";

  useEffect(() => {
    api.passwordResetTokenCheck(
      passwordResetToken,
    ).then((res) => {
      if (res.ok) {
        res.json().then((json) => {
          setInitialErrorMessage("");
          setUsername(json.username);
        })
      } else {
        res.json().then((err) => {
          setInitialErrorMessage(err.message);
        })
      }
    });
  });

  return (
    <>
      {initialErrorMessage !== "?" &&
        <PasswordResetTokenPage username={username} passwordResetToken={passwordResetToken} initialErrorMessage={initialErrorMessage} />
      }
    </>
  );
}
