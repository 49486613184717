import { ReactElement } from "react";

export default function NotFound(): ReactElement {
  return (
    <>
      <div className="notfound__root">
        <h1 className="notfound__title">404</h1>
        <p className="notfound__description">
          Ooops, someone miscoded this page…
        </p>
        <p className="notfound__description_small">
          Please return to the previous page or go to home!
        </p>
        <div className="home__wrapper">
          <div className="btn primary wide">
            <a href="/" className="home__link">
              <span>Go to home</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
