import { MouseEvent, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import styles from "./SidebarLayout.module.css";
import useInMobileView from "../../hooks/use-in-mobile-view";

const SIDEBARWIDTH_COLLAPSE_THRESHOLD_PX = 200;

//TODO: base this on HorizontalLayout
export default function SidebarLayout(props: {
  keyForStorage?: string,
  sidebarClass?: string,
  minimizedTitle: string,
  sidebarContent: ReactNode,
  mainContent: ReactNode,
}): ReactElement {
  let sidebarRef = useRef<HTMLDivElement>(null);
  let sidebarOpenRef = useRef<HTMLDivElement>(null);
  let containerRef = useRef<HTMLDivElement>(null);

  const inMobileView = useInMobileView();

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isSplitterMouseDown, setIsSplitterMouseDown] = useState<boolean>(false);
  const [sidebarWidthState, setSidebarWidthState] = useState<string>("460px");

  const collapsedClass = collapsed ? styles.collapsed : "";
  const movingSplitterClass = isSplitterMouseDown ? styles.moving_splitter_parent : "";
  const movingSplitterPointerEventsClass = isSplitterMouseDown ? styles.moving_splitter_pointer_events : "";

  const sidebarWidth = collapsed ? "0" : sidebarWidthState;

  const storageKey = props.keyForStorage === undefined ? null : `sidebarLayout-width-${props.keyForStorage}`;

  const sidebarStyle = inMobileView ? {} : {
    minWidth: sidebarWidth,
    maxWidth: sidebarWidth,
  };

  var sidebarClass = props.sidebarClass ?? "sidebar";

  function storeSidebarWidth(width: string) {
    if (storageKey === null) return;

    localStorage.setItem(storageKey, width);
  }

  const loadSidebarWidth = useCallback(() => {
    if (storageKey === null) return;

    const storedSidebarWidth = localStorage.getItem(storageKey);
    if (storedSidebarWidth !== null) {
      setSidebarWidthState(storedSidebarWidth);
    }
  }, [storageKey]);

  function sidebarOpenClickHandler(e: MouseEvent<HTMLElement>) {
    sidebarOpenRef.current?.classList.add("hidden");
    sidebarRef.current?.classList.add("open");
  }

  function sidebarCloseClickHandler(e: MouseEvent<HTMLElement>) {
    sidebarRef.current?.classList.remove("open");
    sidebarOpenRef.current?.classList.remove("hidden");
  }

  function onCollapsedClicked() {
    setCollapsed((prev: boolean) => !prev);
  }

  function onSplitterMove(e: MouseEvent<HTMLDivElement>) {
    if (isSplitterMouseDown && containerRef.current) {
      if (e.clientX < SIDEBARWIDTH_COLLAPSE_THRESHOLD_PX) {
        setCollapsed(true);
        setIsSplitterMouseDown(false);
      } else {
        const parentBoundingBox = containerRef.current.getBoundingClientRect();

        const percentage = (e.clientX - parentBoundingBox.x) / parentBoundingBox.width * 100;
        setSidebarWidthState(`${percentage}%`);
      }
    }
  }

  function onSplitterMouseDown(e: MouseEvent<HTMLDivElement>) {
    setIsSplitterMouseDown(true);
  }

  function onSplitterMouseUp(e: MouseEvent<HTMLDivElement>) {
    if (isSplitterMouseDown) {
      if (e.clientX >= SIDEBARWIDTH_COLLAPSE_THRESHOLD_PX) {
        storeSidebarWidth(sidebarWidthState);
      }
      setIsSplitterMouseDown(false);
    }
  }

  useEffect(() => {
    loadSidebarWidth();
  }, [props.keyForStorage, loadSidebarWidth]);

  return (
    <>
      <div ref={containerRef} className={`cy__content ${movingSplitterClass}`} onMouseMove={onSplitterMove} onMouseUp={onSplitterMouseUp}>
        <div ref={sidebarOpenRef} className="sidebar__open" onClick={sidebarOpenClickHandler}>
          <span>{props.minimizedTitle}</span>
          <i className="fas fa-chevron-down"></i>
        </div>

        <div
          ref={sidebarRef}
          className={`${sidebarClass} ${collapsedClass} ${movingSplitterPointerEventsClass}`}
          style={sidebarStyle}
        >
          <div className="sidebar__close" onClick={sidebarCloseClickHandler}>
            <div className="btn narrow danger">
              Close <i className="fas fa-times"></i>
            </div>
          </div>

          <div className={`${styles.sidebar_container} content__wrapper`}>
            {!inMobileView &&
              <button id="sidebar_toggler" type="button" className={`${styles.sidebar_toggler}`} onClick={onCollapsedClicked}>
                {collapsed ? <>&gt;</> : <>&lt;</>}
              </button>
            }
            {props.sidebarContent}
          </div>
        </div>

        {!inMobileView &&
          <div
            className={styles.splitter}
            onMouseDown={onSplitterMouseDown} />
        }

        <div className={`cy__formsWrapper display ${movingSplitterPointerEventsClass} ${styles.right__panel}`}>
          {props.mainContent}
        </div>
      </div>
    </>
  );
}
