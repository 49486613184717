import { ReactElement } from "react";
import styles from "./UserMessage.module.css";
import { IUserMessageOfUser } from "../../messages/IGetUserMessagesOfUserResponse";
import useApiState, { api } from "../../hooks/use-api-state";
import Loading from "../common/Loading";
import ErrorNotification from "../common/ErrorNotification";

export default function UserMessage(props: {
    userId: string;
    userMessage: IUserMessageOfUser;
    onMessageChanged(message: IUserMessageOfUser): void;
}): ReactElement {
    const [apiState, setApiState] = useApiState(false);

    async function markAsRead(markAsRead: boolean) {
        try {
            await api.markUserMessageOfUserAsRead(
                setApiState,
                props.userId,
                props.userMessage.message_source,
                props.userMessage.id,
                markAsRead,
            );
            props.onMessageChanged({
                ...props.userMessage,
                markedAsRead: markAsRead,
            })
        } catch (e) {
        }
    }

    const markedAsReadClass = props.userMessage.markedAsRead ? styles["marked-as-read"] : "";

    if (props.userMessage.type === "inline") {
        return (
            <>
                <div className={`${styles.card} ${markedAsReadClass}`}>
                    <div className="flex-column flex-gap-05em">
                        <div><b>{props.userMessage.payload.subject}</b></div>
                        <div>{props.userMessage.payload.content}</div>
                        {
                            props.userMessage.payload.link &&
                            <div><a href={props.userMessage.payload.link}>{props.userMessage.payload.link}</a></div>
                        }
                    </div>

                    <Loading isLoading={apiState.isLoading}>
                        {!!apiState.errorMessage && !apiState.isUnauthenticated ? (
                            <ErrorNotification errorMessage={apiState.errorMessage} />
                        ) : (
                            <>
                                {
                                    props.userMessage.markedAsRead
                                        ?
                                        <button className={styles["mark-as-read-button"]} onClick={() => markAsRead(false)}>Mark as unread</button>
                                        :
                                        <button className={styles["mark-as-read-button"]} onClick={() => markAsRead(true)}>Mark as read</button>
                                }
                            </>
                        )}
                    </Loading>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div>Unknown message type</div>
            </>
        );
    }
}