import React, { ReactElement, useState } from "react";

export interface INotification {
  message: string;
}

export interface INotificationsContext {
  notifications: INotification[],
  addNotification: (notification: INotification) => void,
}

const NotificationsContext = React.createContext<INotificationsContext>({
  notifications: [],
  addNotification: (notification: INotification) => { },
});

export const NotificationsContextProvider = (props: { children: ReactElement }) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const notificationVanishingTimeMillis = 6000;

  function addNotification(notification: INotification) {
    // push an element on the queue
    setNotifications((state: INotification[]) => {
      return [...state, notification];
    });

    // remove an element from the queue after a given time
    setTimeout(() => {
      setNotifications((state: INotification[]) => {
        let ret = [...state];
        ret.splice(0, 1);
        return ret;
      })
    }, notificationVanishingTimeMillis);
  }

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        addNotification,
      }}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContext;
