import { useEffect, useState } from "react";

export default function useInMobileView() {
  const [inMobileView, setInMobileView] = useState<boolean>(false);

  function checkMobileView() {
    setInMobileView(window.innerWidth <= 992);
  }

  useEffect(() => {
    checkMobileView();

    window.addEventListener("resize", () => checkMobileView());

    return () => {
      window.removeEventListener("resize", () => checkMobileView());
    };
  }, []);

  return inMobileView;
}
