import { ReactElement, useCallback, useEffect, useState } from "react";
import useApiState, { api } from "../../hooks/use-api-state";
import { IGetUserMessagesOfUserResponse, IUserMessageOfUser } from "../../messages/IGetUserMessagesOfUserResponse";
import Loading from "../common/Loading";
import ErrorNotification from "../common/ErrorNotification";
import UserMessage from "./UserMessage";
import styles from "./UserMessages.module.css";

export default function UserMessages(props: {
    userId: string;
}): ReactElement {
    const [apiState, setApiState] = useApiState(true);
    const [userMessages, setUserMessages] = useState<IGetUserMessagesOfUserResponse | null>(null);

    const sortUserMessages = useCallback((userMessages: IGetUserMessagesOfUserResponse | null) => {
        if (userMessages === null) {
            return null;
        }

        userMessages.messages.sort((a: IUserMessageOfUser, b: IUserMessageOfUser) => {
            if (a.markedAsRead === b.markedAsRead) {
                const aDate = new Date(a.validity_from);
                const bDate = new Date(b.validity_from);
                const aToB = bDate.getTime() - aDate.getTime();
                if (aToB === 0) {
                    return a.id < b.id ? -1 : 1;
                } else {
                    return aToB;
                }
            } else {
                return a.markedAsRead ? 1 : -1;
            }
        });
    }, []);

    const getMessageItems = useCallback(async () => {
        try {
            let userMessages = await api.getUserMessagesOfUser(setApiState, props.userId);
            sortUserMessages(userMessages);
            setUserMessages(userMessages);
        } catch (e) {
        }
    }, [setApiState, props.userId, sortUserMessages]);

    function onMessageChanged(message: IUserMessageOfUser) {
        if (userMessages === null) {
            return;
        }

        let newUserMessages = {
            messages: [...userMessages.messages],
        };
        for (let i = 0; i < newUserMessages.messages.length; i++) {
            if (newUserMessages.messages[i].id === message.id) {
                newUserMessages.messages[i] = message;
                break;
            }
        }

        sortUserMessages(newUserMessages);
        setUserMessages(newUserMessages);
    }

    useEffect(() => {
        getMessageItems();
    }, [getMessageItems]);

    return (
        <>
            <Loading isLoading={apiState.isLoading} >
                {!!apiState.errorMessage && !apiState.isUnauthenticated ? (
                    <ErrorNotification errorMessage={apiState.errorMessage} />
                ) : (
                    <>
                        { userMessages !== null && userMessages.messages.length > 0 &&
                            <div className={`dashboard__charts ${styles.messages_chart}`}>
                                <div className={`flex-column flex-gap-05em ${styles.column_width}`}>
                                    {
                                        <>
                                            {userMessages.messages.map((userMessage: IUserMessageOfUser) => {
                                                return <div key={userMessage.id} className={`flex-row flex-gap-05em`}>
                                                    <UserMessage userId={props.userId} userMessage={userMessage} onMessageChanged={onMessageChanged} />
                                                </div>;
                                            })}
                                        </>
                                    }
                                </div>
                            </div>
                        }
                    </>
                )}
            </Loading>
        </>
    );
}