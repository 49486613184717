import { Dispatch, SetStateAction, useState } from "react";
import { IGetCohortListResponse } from "../messages/IGetCohortListResponse";
import { IGetCohortByIdResponse } from "../messages/IGetCohortByIdResponse";
import { IPartnerOrganizationListResponse } from "../messages/IPartnerOrganizationListResponse";
import { IAppInfo } from "../messages/IAppInfo";
import { Month, monthToNumber } from "../utils/month";
import {
  IGetCohortMemberListResponse,
  ISendRegistrationEmailStatus,
  IPollRegistrationEmailStatus,
  IEmailStatus,
} from "../messages/IGetCohortMemberListResponse";
import { IGetCohortFeedbacksResponse } from "../messages/IGetCohortFeedbacksResponse";
import { IGetCohortMemberRegistrationStatusResponse } from "../messages/IGetCohortMemberRegistrationStatusResponse";
import { IGetLogs } from "../messages/IGetPartnerOrganizationLogsResponse";
import { IPartnerOrganizationInvitationListResponse } from "../messages/IPartnerOrganizationInvitationListResponse";
import {
  IAddScheduleResponse,
  IScheduledTasksResponse,
} from "../messages/IScheduledTasksResponse";
import { IGetUserMessagesOfUserResponse } from "../messages/IGetUserMessagesOfUserResponse";
import { IGetUserMessagesOfCohortResponse } from "../messages/IGetUserMessagesOfCohortResponse";
import { IGetUserStatisticsResponse } from "../messages/IGetUserStatisticsResponse";
import { IGetUserContentResponse } from "../messages/IGetUserContentResponse";
import { IMarkSlideVisitedResponse, IRegisterResponse, IRegistrationUserInfo, ILogoutResponse, ICreateCohortResponse, 
         IGenerateCertificateForUser, IGetAccessTokenResponse, IGetCertificateLinkForUserResponse,
         IClientIdResponse } from "../messages/IResponse";

let csrfToken: string | null = null;

export function getCsrfToken() {
  return csrfToken;
}

export interface IApiState {
  isLoading: boolean;
  isUnauthenticated: boolean;
  errorMessage: string | null;
}

export interface IStatusOnlyResponse {
  status: string;
}

export type ApiStateSetter = Dispatch<SetStateAction<IApiState>>;
export type StringStateSetter = Dispatch<SetStateAction<string>>;
export type AppInfoStateSetter = Dispatch<SetStateAction<IAppInfo | null>>;

export default function useApiState(
  initiallyLoading: boolean
): [IApiState, ApiStateSetter] {
  return useState<IApiState>({
    isLoading: initiallyLoading,
    isUnauthenticated: false,
    errorMessage: null,
  });
}

export namespace api {

  async function handleResponse<RetType>(
    setApiState: ApiStateSetter,
    fetchResponse: Promise<Response>,
    okHandler: (response: Response) => Promise<RetType | null>
  ): Promise<RetType> {
    setApiState({
      isLoading: true,
      isUnauthenticated: false,
      errorMessage: null,
    });

    return fetchResponse.then(
      (response: Response) => {
        if (response.ok) {
          if (response.redirected) {
            const url = new URL(response.url);
            let path = url.pathname;
            if (path.startsWith("/accounts/login")) {
              url.search = "next=" + window.location.pathname+"?"+window.location.search;
              window.alert("Your session expired. You have to log in again.");
              window.location.href=url.toString();
              return Promise.reject(response);
            }
          }
          const ret = okHandler(response)
            .then((value: RetType | null) => {
              setApiState((state: IApiState) => {
                return {
                  ...state,
                  isLoading: false,
                };
              });
              if (value !== null) {
                return value;
              } else {
                setApiState((state: IApiState) => {
                  return {
                    ...state,
                    errorMessage: "Unknown error",
                  };
                });
                return Promise.reject(response);
              }
            })
            .catch((reason: any) => {
              console.log(`EXCEPTION: ${reason}`);
              setApiState((state: IApiState) => {
                return {
                  ...state,
                  isLoading: false,
                  errorMessage: "Unknown error",
                };
              });
              return Promise.reject(response);
            });
          return ret;
        } else if (response.status === 401) {
          setApiState({
            isLoading: false,
            isUnauthenticated: true,
            errorMessage: response.statusText,
          });
          return Promise.reject(response);
        } else if (response.status === 429) {
          setApiState({
            isLoading: false,
            isUnauthenticated: true,
            errorMessage: "Too many requests",
          });
          return Promise.reject(response);
        } else if (response.status === 403) {
          setApiState((state: IApiState) => {
            return {
              ...state,
              isLoading: false,
              errorMessage: response.statusText,
            };
          });
          return Promise.reject(response);
        } else {
          response.json().then(
            (value: { message: string }) => {
              if (value.message === undefined) {
                setApiState((state: IApiState) => {
                  return {
                    ...state,
                    isLoading: false,
                    errorMessage: "Unknown error",
                  };
                });
              } else {
                setApiState((state: IApiState) => {
                  return {
                    ...state,
                    isLoading: false,
                    errorMessage: `${value.message}`,
                  };
                });
              }
            },
            (e: any) => {
              setApiState((state: IApiState) => {
                return {
                  ...state,
                  isLoading: false,
                  errorMessage: `Status = ${response.status}, StatusText = ${response.statusText}`,
                };
              });
            }
          );
          return Promise.reject(response);
        }
      },
      (reason: any) => {
        console.log(`REASON: ${reason}`);
        setApiState((state: IApiState) => {
          return {
            ...state,
            isLoading: false,
            errorMessage: "Unknown error",
          };
        });
        return Promise.reject(reason);
      }
    );
  }

  export async function revokeInvitation(
    setApiState: ApiStateSetter,
    partnerOrganizationId: string,
    invitationId: string
  ) {
    let response = fetch(
      `/api/private/partner-organizations/${partnerOrganizationId}/invitations/${invitationId}/email`,
      {
        method: "DELETE",
        headers: {
          "X-CSRFToken": csrfToken ?? "",
        },
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        await response.text();
      }
    );
  }

  export async function sendInvitationOfPartnerOrganization(
    setApiState: ApiStateSetter,
    partnerOrganizationId: string,
    email: string,
    topic: string
  ) {
    let response = fetch(
      `/api/private/partner-organizations/${partnerOrganizationId}/invitations`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken ?? "",
        },
        body: JSON.stringify({
          email: email,
          topic: topic,
        }),
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        await response.text();
      }
    );
  }

  export async function getInvitationsOfPartnerOrganization(
    setApiState: ApiStateSetter,
    partnerOrganizationId: string
  ) {
    let response = fetch(
      `/api/private/partner-organizations/${partnerOrganizationId}/invitations`,
      {
        method: "GET",
        headers: {
          "X-CSRFToken": csrfToken ?? "",
        },
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = await response.json();
        return data as IPartnerOrganizationInvitationListResponse;
      }
    );
  }

  export async function getPartnerOrganizationList(
    setApiState: ApiStateSetter
  ) {
    let response = fetch(`/api/private/partner-organizations`, {
      method: "GET",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = await response.json();
        return data as IPartnerOrganizationListResponse;
      }
    );
  }

  export async function getAccessTokenForPartnerOganization(
    setApiState: ApiStateSetter,
    partnerOganizationId: string,
    expirationTimeInDays: number
  ) {
    let response = fetch(
      `/api/private/partner-organizations/${partnerOganizationId}/access-token?expiration_time_in_days=${expirationTimeInDays}`,
      {
        method: "GET",
        headers: {
          "X-CSRFToken": csrfToken ?? "",
        },
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IGetAccessTokenResponse;
        return data.access_token;
      }
    );
  }

  export async function getPartnerOrganizationLogs(
    setApiState: ApiStateSetter,
    partnerOganizationId: string,
    month: Month,
    year: number
  ) {
    // todo!(): do we have a constant here for "user_add" ?
    const eventType = "user_add";
    const monthNumber = monthToNumber(month);
    let response = fetch(
      `/api/private/partner-organizations/${partnerOganizationId}/logs?year=${year}&month=${monthNumber}&event_type=${eventType}`,
      {
        method: "GET",
        headers: {
          "X-CSRFToken": csrfToken ?? "",
        },
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IGetLogs;
        return data as IGetLogs;
      }
    );
  }

  // This function does not provide the X-CSRFToken header, because it is actually the one that gets it initially
  export async function getAppInfo(setApiState: ApiStateSetter) {
    let response = fetch(`/api/private/app-info`, {
      method: "GET",
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IAppInfo;
        csrfToken = data.csrfToken;
        return data;
      }
    );
  }

  // This function does not provide the X-CSRFToken header, because it actually gets it
  export async function getUserInfoWithRegistrationToken(
    setApiState: ApiStateSetter,
    registrationToken: string
  ) {
    let response = fetch(
      `/api/private/users?registration_token=${registrationToken}`,
      {
        method: "GET",
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IRegistrationUserInfo;
        return data;
      }
    );
  }

  export async function register(
    setApiState: ApiStateSetter,
    registrationToken: string,
    fullname: string,
    password: string
  ) {
    let response = fetch(`/api/private/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      body: JSON.stringify({
        registration_token: registrationToken,
        fullname: fullname,
        password: password,
      }),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IRegisterResponse;
        return data;
      }
    );
  }

  export async function logout(setApiState: ApiStateSetter) {
    let response = fetch(`/accounts/logout`, {
      method: "GET",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as ILogoutResponse;
        return data;
      }
    );
  }

  export async function triggerForgottenPassword(
    setApiState: ApiStateSetter,
    email: string
  ) {
    let response = fetch(`/api/private/forgotten-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      body: JSON.stringify({
        email,
      }),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        await response.text;
        return;
      }
    );
  }

  export async function passwordResetTokenCheck(passwordResetToken: string) {
    let response = fetch(
      `/api/private/password-reset?password_reset_token=${passwordResetToken}`,
      {
        method: "GET",
        headers: {
          "X-CSRFToken": csrfToken ?? "",
        },
      }
    );

    return response;
  }

  export async function passwordReset(
    setApiState: ApiStateSetter,
    password: string,
    passwordResetToken: string | null = null,
    registrationToken: string | null = null
  ) {
    let body: {
      password: string;
      password_reset_token?: string;
      registration_token?: string;
    } = { password: password };
    if (passwordResetToken) body["password_reset_token"] = passwordResetToken;
    if (registrationToken) body["registration_token"] = registrationToken;
    let response = fetch(`/api/private/password-reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      body: JSON.stringify(body),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        return await response.json();
      }
    );
  }

  export async function checkPassword(
    password: string,
    optionals: {
      // optional parameters in a dict, any can be omitted
      passwordResetToken?: string;
      registrationToken?: string;
      setErrorMessage?: StringStateSetter;
    }
  ) {
    let body: {
      password: string;
      password_reset_token?: string;
      registration_token?: string;
    } = { password: password };
    if (typeof optionals.passwordResetToken !== "undefined")
      body["password_reset_token"] = optionals.passwordResetToken;
    if (typeof optionals.registrationToken !== "undefined")
      body["registration_token"] = optionals.registrationToken;
    let response = fetch(`/api/private/check-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      body: JSON.stringify(body),
    });

    response.then((resp) => {
      resp.json().then((ret) => {
        if (typeof optionals.setErrorMessage !== "undefined") {
          if (ret.status === "OK") {
            optionals.setErrorMessage("");
          } else {
            optionals.setErrorMessage(ret.message);
          }
        }
      });
    });
  }

  export async function changePassword(
    setApiState: ApiStateSetter,
    currentPassword: string,
    password: string
  ) {
    let response = fetch(`/api/private/password`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      body: JSON.stringify({
        current_password: currentPassword,
        password,
      }),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        return await response.json();
      }
    );
  }

  export async function createCohort(
    setApiState: ApiStateSetter,
    trainingTitle: string,
    topic: string,
    cohortCode: string,
    trainerName: string,
    companyName: string,
    countryCode: string,
    startDateSecondsUtc: number,
    endDateSecondsUtc: number,
    expires_after_days: number,
    sergeantVersion: string,
    attendantTemplateJsonFilename: string
  ) {
    let response = fetch(`/api/private/cohorts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      body: JSON.stringify({
        code: cohortCode,
        trainername: trainerName,
        startdate_secs_utc: startDateSecondsUtc,
        enddate_secs_utc: endDateSecondsUtc,
        expires_after_days: expires_after_days,
        companyname: companyName,
        countrycode: countryCode,
        title: trainingTitle,
        topic: topic,
        sergeantversion: sergeantVersion,
        attendant_template_json_filename: attendantTemplateJsonFilename,
      }),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as ICreateCohortResponse;
        return data;
      }
    );
  }

  export async function updateCohort(
    setApiState: ApiStateSetter,
    cohortId: string,
    trainingTitle: string | null,
    topic: string | null,
    cohortCode: string | null,
    trainerName: string | null,
    companyName: string | null,
    countryCode: string | null,
    startDateSecondsUtc: number | null,
    endDateSecondsUtc: number | null,
    timeZone: string | null,
    startTime: string | null,
    endTime: string | null,
    expires_after_days: number | null,
    sergeantVersion: string | null,
    attendantTemplateJsonFilename: string | null
  ) {
    let response = fetch(`/api/private/cohorts/${cohortId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      body: JSON.stringify({
        code: cohortCode,
        trainername: trainerName,
        startdate_secs_utc: startDateSecondsUtc,
        enddate_secs_utc: endDateSecondsUtc,
        timezone: timeZone,
        start_time: startTime,
        end_time: endTime,
        expires_after_days: expires_after_days,
        companyname: companyName,
        countrycode: countryCode,
        title: trainingTitle,
        topic: topic,
        sergeantversion: sergeantVersion,
        attendant_template_json_filename: attendantTemplateJsonFilename,
      }),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        await response.text();
      }
    );
  }

  export async function getCohortList(
    setApiState: ApiStateSetter,
    month: Month,
    year: number
  ) {
    let monthParam;
    switch (month) {
      case Month.January: {
        monthParam = "jan";
        break;
      }
      case Month.February: {
        monthParam = "feb";
        break;
      }
      case Month.March: {
        monthParam = "mar";
        break;
      }
      case Month.April: {
        monthParam = "apr";
        break;
      }
      case Month.May: {
        monthParam = "may";
        break;
      }
      case Month.June: {
        monthParam = "jun";
        break;
      }
      case Month.July: {
        monthParam = "jul";
        break;
      }
      case Month.August: {
        monthParam = "aug";
        break;
      }
      case Month.September: {
        monthParam = "sep";
        break;
      }
      case Month.October: {
        monthParam = "oct";
        break;
      }
      case Month.November: {
        monthParam = "nov";
        break;
      }
      case Month.December: {
        monthParam = "dec";
        break;
      }
    }

    let response = fetch(`/api/private/cohorts?month=${year}${monthParam}`, {
      method: "GET",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IGetCohortListResponse;
        return data;
      }
    );
  }

  export async function getCohortById(
    setApiState: ApiStateSetter,
    cohortId: string
  ) {
    let response = fetch(`/api/private/cohorts/${cohortId}`, {
      method: "GET",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IGetCohortByIdResponse;
        return data;
      }
    );
  }

  export async function getCohortMemberList(
    setApiState: ApiStateSetter,
    cohortId: string
  ) {
    let response = fetch(`/api/private/cohorts/${cohortId}/members`, {
      method: "GET",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IGetCohortMemberListResponse;
        return data;
      }
    );
  }

  export async function addCohortMember(
    setApiState: ApiStateSetter,
    cohortId: string,
    email: string
  ) {
    let response = fetch(`/api/private/cohorts/${cohortId}/members`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      body: JSON.stringify({
        email,
      }),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = await response.json();
        return data.membercount;
      }
    );
  }

  export async function getCohortFeedbacks(
    setApiState: ApiStateSetter,
    cohortId: string
  ) {
    let response = fetch(`/api/private/cohorts/${cohortId}/feedbacks`, {
      method: "GET",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IGetCohortFeedbacksResponse;
        return data;
      }
    );
  }

  export async function addScheduledTask(
    setApiState: ApiStateSetter,
    cohortId: string,
    schedule: {}
  ) {
    let response = fetch(`/api/private/scheduled-tasks/${cohortId}`, {
      method: "POST",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(schedule),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IAddScheduleResponse;
        return data;
      }
    );
  }

  export async function addScheduledTaskAt(
    setApiState: ApiStateSetter,
    cohortId: string,
    taskType: string,
    timezone: string | null = "UTC",
    year: number | null = null,
    month: number | null = null,
    day: number | null = null,
    hour: number | null = null,
    minute: number | null = null
  ) {
    let schedule = {
      task_type: taskType,
      timezone: timezone,
      schedule_type: "at",
      year: year,
      month: month,
      day: day,
      hour: hour,
      minute: minute,
      second: 0,
    };
    return await addScheduledTask(setApiState, cohortId, schedule);
  }

  export async function addScheduledTaskCron(
    setApiState: ApiStateSetter,
    cohortId: string,
    taskType: string,
    timezone: string = "UTC",
    hours: string | null,
    minutes: string | null,
    dayOfMonth: string | null,
    month: string | null,
    dayOfWeek: string | null,
    year: string | null
  ) {
    let schedule: {
      task_type: string;
      timezone: string;
      schedule_type: string;
      hours?: string | null;
      minutes?: string | null;
      day_of_month?: string | null;
      month?: string | null;
      day_of_week?: string | null;
      year?: string | null;
    } = {
      task_type: taskType,
      timezone: timezone,
      schedule_type: "cron",
    };
    if (hours) schedule["hours"] = hours;
    if (minutes) schedule["minutes"] = minutes;
    if (dayOfMonth) schedule["day_of_month"] = dayOfMonth;
    if (month) schedule["month"] = month;
    if (dayOfWeek) schedule["day_of_week"] = dayOfWeek;
    if (year) schedule["year"] = year;
    return await addScheduledTask(setApiState, cohortId, schedule);
  }

  export async function deleteScheduledTask(
    setApiState: ApiStateSetter,
    cohortId: string,
    taskId: String
  ) {
    let schedule = {
      task_id: taskId,
    };
    let response = fetch(`/api/private/scheduled-tasks/${cohortId}`, {
      method: "DELETE",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(schedule),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IAddScheduleResponse;
        return data;
      }
    );
  }

  export async function getCohortScheduledTasksOfCohort(
    setApiState: ApiStateSetter,
    cohortId: string
  ) {
    let response = fetch(`/api/private/scheduled-tasks/${cohortId}`, {
      method: "GET",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IScheduledTasksResponse;
        return data;
      }
    );
  }

  export async function setScheduledTasksEnabled(
    setApiState: ApiStateSetter,
    cohortId: string,
    taskId: string,
    enable: boolean
  ) {
    let response = fetch(
      `/api/private/scheduled-tasks/${cohortId}/${taskId}/${
        enable ? "enable" : "disable"
      }`,
      {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfToken ?? "",
        },
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IStatusOnlyResponse;
        return data;
      }
    );
  }

  export async function generateCertificateForUser(
    setApiState: ApiStateSetter,
    cohortId: string,
    username: string
  ) {
    let response = fetch(
      `/api/private/users/${username}/${cohortId}/certificate/attendance`,
      {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfToken ?? "",
        },
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IGenerateCertificateForUser;
        return data;
      }
    );
  }

  export async function pollRegistrationEmail(
    setApiState: ApiStateSetter,
    to_poll: string[],
    cohort_id: string,
    force: boolean
  ) {
    let response = fetch(`/api/private/poll-registration-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      body: JSON.stringify({
        to_poll,
        cohort_id,
        force,
      }),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IPollRegistrationEmailStatus;
        return data;
      }
    );
  }

  export async function sendRegistrationEmail(
    setApiState: ApiStateSetter,
    user_id: string,
    cohort_id: string,
    force: boolean
  ) {
    let response = fetch(`/api/private/send-registration-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      body: JSON.stringify({
        user_id,
        cohort_id,
        force,
      }),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as ISendRegistrationEmailStatus;
        return data;
      }
    );
  }

  export async function pollEvalformEmail(
    setApiState: ApiStateSetter,
    cohortId: string,
    userIds: string[],
    force: boolean
  ) {
    let response = fetch(`/api/private/evalform-email/poll/${cohortId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      body: JSON.stringify({ users: userIds }),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IEmailStatus;
        //console.log(`POLL response=${JSON.stringify(data)}`);
        return data;
      }
    );
  }

  export async function sendEvalformEmails(
    setApiState: ApiStateSetter,
    cohortId: string,
    userIds: string[]
  ) {
    let response = fetch(`/api/private/evalform-email/send/${cohortId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken ?? "",
      },
      body: JSON.stringify({ users: userIds }),
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IEmailStatus;
        return data;
      }
    );
  }

  export async function pollCertificateEmail(
    setApiState: ApiStateSetter,
    cohortId: string,
    userIds: string[],
    force: boolean
  ) {
    let response = fetch(
      `/api/private/certificate-email/poll/attendance/${cohortId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken ?? "",
        },
        body: JSON.stringify({ users: userIds, force: force }),
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IEmailStatus;
        //console.log(`POLL response=${JSON.stringify(data)}`);
        return data;
      }
    );
  }

  export async function sendCertificateEmails(
    setApiState: ApiStateSetter,
    cohortId: string,
    userIds: string[]
  ) {
    let response = fetch(
      `/api/private/certificate-email/send/attendance/${cohortId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken ?? "",
        },
        body: JSON.stringify({ users: userIds }),
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IEmailStatus;
        return data;
      }
    );
  }

  export async function pollAdultTrainingHuInfoEmail(
    setApiState: ApiStateSetter,
    cohortId: string,
    userIds: string[],
    force: boolean
  ) {
    let response = fetch(
      `/api/private/adult-training-hu-info-email/poll/${cohortId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken ?? "",
        },
        body: JSON.stringify({ users: userIds, force: force }),
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IEmailStatus;
        //console.log(`POLL response=${JSON.stringify(data)}`);
        return data;
      }
    );
  }

  export async function sendAdultTrainingHuInfoEmail(
    setApiState: ApiStateSetter,
    cohortId: string,
    userIds: string[]
  ) {
    let response = fetch(
      `/api/private/adult-training-hu-info-email/send/${cohortId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken ?? "",
        },
        body: JSON.stringify({ users: userIds }),
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = (await response.json()) as IEmailStatus;
        return data;
      }
    );
  }

  export async function getCohortMemberRegistrationStatus(
    setApiState: ApiStateSetter,
    email: string,
    cohortId: string
  ) {
    let response = fetch(
      `/api/private/cohort/${cohortId}/member-registration-status/${email}`,
      {
        method: "GET",
        headers: {
          "X-CSRFToken": csrfToken ?? "",
        },
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data =
          (await response.json()) as IGetCohortMemberRegistrationStatusResponse;
        return data;
      }
    );
  }

  export async function getCertificateLinkForUser(
    setApiState: ApiStateSetter,
    cohortId: string,
    email: string
  ) {
    let response = fetch(
      `/api/private/users/${email}/${cohortId}/certificate/attendance`,
      {
        method: "GET",
        headers: {
          "X-CSRFToken": csrfToken ?? "",
        },
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data =
          (await response.json()) as IGetCertificateLinkForUserResponse;
        return data;
      }
    );
  }

  export async function getUserMessagesOfUser(
    setApiState: ApiStateSetter,
    userId: string
  ) {
    let response = fetch(`/api/private/users/${userId}/messages`, {
      method: "GET",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = await response.json();
        return data as IGetUserMessagesOfUserResponse;
      }
    );
  }

  export async function markUserMessageOfUserAsRead(
    setApiState: ApiStateSetter,
    userId: string,
    messageSource: string,
    messageId: string,
    markAsRead: boolean
  ) {
    let response = fetch(
      `/api/private/users/${userId}/messages/${messageSource}/${messageId}`,
      {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfToken ?? "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          markedAsRead: markAsRead,
        }),
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        await response.text();
      }
    );
  }

  export async function getUserMessagesOfCohort(
    setApiState: ApiStateSetter,
    cohortId: string
  ) {
    let response = fetch(`/api/private/cohorts/${cohortId}/messages`, {
      method: "GET",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = await response.json();
        return data as IGetUserMessagesOfCohortResponse;
      }
    );
  }

  export async function getUserStatistics(
    setApiState: ApiStateSetter,
    userId: string
  ) {
    let response = fetch(`/api/private/users/${userId}/statistics`, {
      method: "GET",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = await response.json();
        return data as IGetUserStatisticsResponse;
      }
    );
  }

  export async function getUserContent(
    setApiState: ApiStateSetter,
    userId: string,
  ) {
    const params = new URLSearchParams(document.location.search);

    const unit = params.get("unit");
    const date = params.get("date");
    const enable = params.get("enable");

    let unitParam = unit ? `&unit=${unit}` : "";
    let dateParam = date ? `&date=${date}` : "";
    let enableParam = enable ? `&enable=${enable}` : "";
    let debugSearchParams = unitParam + dateParam + enableParam;
    if (debugSearchParams.length > 0) {
      debugSearchParams = "?" + debugSearchParams.substring(1);
    }
    let response = fetch(`/api/private/users/${userId}/content${debugSearchParams}`, {
      method: "GET",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
    });

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = await response.json();
        return data as IGetUserContentResponse;
      }
    );
  }

  export async function markSlideVisited(
    setApiState: ApiStateSetter,
    cohortId: string,
    contentId: string,
    chapterId: string,
    moduleId: string,
    slideId: string,
  ) {
    let response = fetch(
      `/slidevisited/${encodeURIComponent(cohortId)}/${encodeURIComponent(contentId)}/${encodeURIComponent(chapterId)}/${encodeURIComponent(moduleId)}/${encodeURIComponent(slideId)}`,
      {
        method: "GET",
        headers: {
          "X-CSRFToken": csrfToken ?? "",
        },
      }
    );

    return await handleResponse(
      setApiState,
      response,
      async function (response: Response) {
        let data = await response.json();
        return data as IMarkSlideVisitedResponse;
      }
    );
  }
}
