import { FormEvent, ReactElement, useCallback, useState } from "react";
import useApiState, { api } from "../../hooks/use-api-state";
import {
  IGetLogs,
  ILogEntry,
} from "../../messages/IGetPartnerOrganizationLogsResponse";
import { Month } from "../../utils/month";
import ErrorNotification from "../common/ErrorNotification";
import Loading from "../common/Loading";
import MonthAndYearSelector from "../common/MonthAndYearSelector";
import PartnerOrganizationSelector from "./PartnerOrganizationSelector";
import styles from "./BillingReport.module.css";
import { IPartnerOrganization } from "../../model/IPartnerOrganization";
import SidebarLayout from "../common/SidebarLayout";

export default function BillingReport(): ReactElement {
  const [getPartnerOrganizationLogsApiState, setGetPartnerOrganizationLogsApiState] = useApiState(false);

  const [selectedPartnerOrganization, setSelectedPartnerOrganization] =
    useState<IPartnerOrganization | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<Month | null>(null);

  const [requestedPartnerOrganization, setRequestedPartnerOrganization] =
    useState<IPartnerOrganization | null>(null);
  const [requestedYear, setRequestedYear] = useState<number | null>(null);
  const [requestedMonth, setRequestedMonth] = useState<Month | null>(null);

  const [logs, setLogs] = useState<IGetLogs | null>(null);

  async function submitHandler(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (
      selectedPartnerOrganization !== null &&
      selectedMonth !== null &&
      selectedYear !== null
    ) {
      setLogs(null);

      setRequestedMonth(selectedMonth);
      setRequestedYear(selectedYear);
      setRequestedPartnerOrganization(selectedPartnerOrganization);

      try {
        const logs =
          await api.getPartnerOrganizationLogs(
            setGetPartnerOrganizationLogsApiState,
            selectedPartnerOrganization.id,
            selectedMonth,
            selectedYear
          );

        if (logs != null) {
          setLogs(logs);
        }
      } catch (e: any) { }
    }
  }

  function partnerOrganizationSelected(
    partnerOrganization: IPartnerOrganization | null
  ) {
    setSelectedPartnerOrganization(partnerOrganization);
  }

  function getTimeStr(timest: number) {
    return new Date(timest * 1000).toLocaleString();
  }

  const monthAndYearChangeHandler = useCallback(
    function (month: Month, year: number) {
      setSelectedMonth(month);
      setSelectedYear(year);
    }, []);

  const isSubmitButtonEnabled =
    selectedPartnerOrganization !== null &&
    selectedMonth !== null &&
    selectedYear !== null;

  return (
    <>
      <div className="cy__dashboard__forms">
        <SidebarLayout
          keyForStorage="billingReport"
          minimizedTitle="Billing report filters"
          sidebarContent={
            <form className={styles.sidebar_container} onSubmit={submitHandler}>
              <div className={styles.row}>
                <div>
                  <PartnerOrganizationSelector
                    required
                    onPartnerOrganizationSelected={partnerOrganizationSelected}
                  />
                </div>

                <div>
                  <MonthAndYearSelector
                    onChange={monthAndYearChangeHandler}
                    required
                  />
                </div>

                <div>
                  <Loading isLoading={getPartnerOrganizationLogsApiState.isLoading}>
                    <button
                      className="btn primary wide"
                      type="submit"
                      disabled={!isSubmitButtonEnabled}
                    >
                      Get logs
                    </button>

                    <ErrorNotification
                      errorMessage={getPartnerOrganizationLogsApiState.errorMessage}
                    />
                  </Loading>
                </div>
              </div>
            </form>
          }
          mainContent={
            <div className="cy__forms">
              {logs !== null && (
                <>
                  <h1>Billing report of {requestedPartnerOrganization?.name}</h1>

                  <div>
                    Added <b>{logs.logs.length}</b> users in{" "}
                    <b>
                      {requestedYear} {requestedMonth}
                    </b>
                    <ul className="loglist">
                      {logs.logs.map((log: ILogEntry, index: number) => {
                        return (
                          <li key={index}>
                            {getTimeStr(log.timest)}: Added {log.username} via{" "}
                            {log.added_via}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              )}
            </div>
          }
        />
      </div>
    </>
  );
}
