import { FormEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Month, stringToMonth, monthToNumber, numberToMonth } from "../../utils/month";
import { dateMonthToString } from "../../utils/time";
import styles from "./MonthAndYearSelector.module.css";

export default function MonthAndYearSelector(props: {
  required: boolean;
  onChange(month: Month | null, year: number | null): void;
}): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedMonth, setSelectedMonth] = useState<Month | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  const [isMonthTouched, setIsMonthTouched] = useState(false);
  const [isYearTouched, setIsYearTouched] = useState(false);

  useEffect(() => {
    let year = searchParams.get("year");
    let month = searchParams.get("month");

    let monthEnum = null;
    if (month !== null) {
      monthEnum = stringToMonth(month);
    }

    let yearNum = null;
    if (year !== null) {
      yearNum = +year;
    }

    if (monthEnum === null && yearNum === null) {
      let sp = new URLSearchParams(searchParams);
      if (yearNum === null) {
        sp.set("year", `${new Date().getFullYear()}`);
      }
      if (monthEnum === null) {
        sp.set("month", dateMonthToString(new Date()));
      }
      setSearchParams(sp, { replace: true });
    } else {
      if (selectedMonth !== monthEnum || selectedYear !== yearNum) {
        if (selectedMonth !== monthEnum) {
          setSelectedMonth(monthEnum);
        }
        if (selectedYear !== yearNum) {
          setSelectedYear(yearNum);
        }

        props.onChange(monthEnum, yearNum);
      }
    }
  }, [searchParams, setSearchParams, selectedMonth, selectedYear, props]);

  function monthBlurHandler(e: FormEvent<HTMLSelectElement>) {
    setIsMonthTouched(true);
  }

  function yearBlurHandler(e: FormEvent<HTMLInputElement>) {
    setIsYearTouched(true);
  }

  function removeSelectedCohordId(sp: URLSearchParams) {
    sp.delete("selected-cohort-id");
  }

  function monthChangeHandler(e: FormEvent<HTMLSelectElement>) {
    setIsMonthTouched(true);

    let sp = new URLSearchParams(searchParams);
    sp.delete("month");

    const month = e.currentTarget.value;
    if (month !== "none") {
      sp.set("month", month);
    }
    removeSelectedCohordId(sp);
    setSearchParams(sp);
  }

  function yearChangedHandler(e: FormEvent<HTMLInputElement>) {
    setIsYearTouched(true);

    let sp = new URLSearchParams(searchParams);
    sp.set("year", e.currentTarget.value);
    removeSelectedCohordId(sp);
    setSearchParams(sp);
  }

  function decreaseMonthHandler(e: MouseEvent<HTMLElement>) {
    if (selectedMonth) {
      const monthNumber = monthToNumber(selectedMonth);
      let sp = new URLSearchParams(searchParams);
      if (monthNumber > 1) {
        sp.set("month", numberToMonth(monthNumber - 1));
      } else {
        sp.set("month", numberToMonth(12));
        if (selectedYear)
          sp.set("year", `${selectedYear - 1}`);
      }
      removeSelectedCohordId(sp);
      setSearchParams(sp);
    }
  }

  function increaseMonthHandler(e: MouseEvent<HTMLElement>) {
    if (selectedMonth) {
      const monthNumber = monthToNumber(selectedMonth);
      let sp = new URLSearchParams(searchParams);
      if (monthNumber < 12) {
        sp.set("month", numberToMonth(monthNumber + 1));
      } else {
        sp.set("month", numberToMonth(1));
        if (selectedYear)
          sp.set("year", `${selectedYear + 1}`);
      }
      removeSelectedCohordId(sp);
      setSearchParams(sp);
    }
  }

  const monthInputErrorClass =
    isMonthTouched && selectedMonth === null && props.required ? "cy__err" : "";

  const yearInputErrorClass =
    isYearTouched && selectedYear === null && props.required ? "cy__err" : "";

  return (
    <>
      <div className={styles.row}>
        <div className={styles.row}>
          <div>
            <label htmlFor="month-selector">Month:</label>
          </div>
          <div>
            <select
              className={`${styles.month_selector} ${monthInputErrorClass}`}
              name="month"
              id="month-selector"
              value={
                selectedMonth === null ? "none" : selectedMonth.toLowerCase()
              }
              onChange={monthChangeHandler}
              onBlur={monthBlurHandler}
              required={props.required}
            >
              <option value="none">Select a month...</option>
              <option value="january">January</option>
              <option value="february">February</option>
              <option value="march">March</option>
              <option value="april">April</option>
              <option value="may">May</option>
              <option value="june">June</option>
              <option value="july">July</option>
              <option value="august">August</option>
              <option value="september">September</option>
              <option value="october">October</option>
              <option value="november">November</option>
              <option value="december">December</option>
            </select>
          </div>
          <div className={`${styles.month_changer}`}>
            <div onClick={increaseMonthHandler}>&#9650;</div>
            <div onClick={decreaseMonthHandler}>&#9660;</div>
          </div>
        </div>
        <div className={styles.row}>
          <div>
            <label htmlFor="year-selector">Year:</label>
          </div>
          <div>
            <input
              className={`${styles.year_selector} ${yearInputErrorClass}`}
              type="number"
              value={selectedYear !== null ? selectedYear : ""}
              id="year-selector"
              onChange={yearChangedHandler}
              onBlur={yearBlurHandler}
              required={props.required}
            ></input>
          </div>
        </div>
      </div>
    </>
  );
}
