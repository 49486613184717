import { ReactElement, useContext, useEffect, useState } from "react";
import useApiState, { api } from "../../../hooks/use-api-state";
import { IGetCohortByIdResponse } from "../../../messages/IGetCohortByIdResponse";
import {
  ICohortFeedback,
  IGetCohortFeedbacksResponse,
} from "../../../messages/IGetCohortFeedbacksResponse";
import NotificationsContext from "../../../store/notifications-context";
import Loading from "../../common/Loading";
import styles from "./CohortFeedbacks.module.css";

export default function CohortFeedbacks(props: {
  cohort: IGetCohortByIdResponse;
}): ReactElement {
  const notificationsContext = useContext(NotificationsContext);
  const [apiState, setApiState] = useApiState(false);
  const [cohortFeedbacks, setCohortFeedbacks] =
    useState<IGetCohortFeedbacksResponse | null>(null);

  useEffect(() => {
    api
      .getCohortFeedbacks(setApiState, props.cohort.id)
      .then((feedbacks: IGetCohortFeedbacksResponse) => {
        setCohortFeedbacks(feedbacks);
      });
  }, [props.cohort.id, setApiState]);

  function copyFeedbackData() {
    let feedbackString = "";
    if (cohortFeedbacks !== null) {
      for (const feedback of cohortFeedbacks.feedbacks) {
        feedbackString += `${props.cohort.code}\t${feedback.name}\t${feedback.email}\t${feedback.timestr}\t`;
        feedbackString += `${feedback.relevance}\t${feedback.objectives}\t${feedback.pace}\t${feedback.duration}\t${feedback.material}\t`;
        feedbackString += `${feedback.labs}\t${feedback.instructor}\t${feedback.presentation}\t${feedback.overall}\t${feedback.promote}\t`;
        feedbackString += `${feedback.like.replace(/(\r\n|\n|\r)/gm, "")}\t`;
        feedbackString += `${feedback.improve.replace(/(\r\n|\n|\r)/gm, "")}\t`;
        feedbackString += `${feedback.action1.replace(/(\r\n|\n|\r)/gm, "")}\t`;
        feedbackString += `${feedback.action2.replace(/(\r\n|\n|\r)/gm, "")}\t`;
        feedbackString += `${feedback.action3.replace(/(\r\n|\n|\r)/gm, "")}\t`;
        feedbackString += `\n`;
      }
    }

    if (feedbackString.length !== 0) {
      navigator.clipboard.writeText(feedbackString);
      notificationsContext.addNotification({
        message: "Feedback data copied to the clipboard",
      });
    } else {
      notificationsContext.addNotification({
        message: "There is no data to be copied to the clipboard",
      });
    }
  }

  return (
    <>
      <Loading isLoading={apiState.isLoading}>
        <div className={styles.feedback_table_header}>
          <div>
            <b>
              Received{" "}
              {cohortFeedbacks === null || cohortFeedbacks.feedbacks.length}{" "}
              forms out of {props.cohort.membercount}
            </b>
          </div>
          <button
            className="btn primary narrow"
            onClick={copyFeedbackData}
            disabled={
              cohortFeedbacks === null || cohortFeedbacks.feedbacks.length === 0
            }
          >
            Put feedback data to clipboard
          </button>
        </div>
        <div className="cy__form form__evaluations">
          <div className={styles.feedbacks_container}>
            <table className={`styled-table ${styles.feedback_table}`}>
              <thead>
                <tr>
                  <th
                    className={`${styles.table_text2} ${styles.table_text_header}`}
                  >
                    Name
                  </th>
                  <th
                    className={`${styles.table_text4} ${styles.table_text_header}`}
                  >
                    Email
                  </th>
                  <th className={styles.table_num_header}>Relevance</th>
                  <th className={styles.table_num_header}>Expectations</th>
                  <th className={styles.table_num_header}>Pace</th>
                  <th className={styles.table_num_header}>Duration</th>
                  <th className={styles.table_num_header}>Material quality</th>
                  <th className={styles.table_num_header}>Labs</th>
                  <th className={styles.table_num_header}>
                    Instructor knowledge
                  </th>
                  <th className={styles.table_num_header}>
                    Instructor presentation
                  </th>
                  <th className={styles.table_num_header}>Overwall feeling</th>
                  <th className={styles.table_num_header}>
                    Recommend to others
                  </th>
                  <th
                    className={`${styles.table_text6} ${styles.table_text_header}`}
                  >
                    Positive
                  </th>
                  <th
                    className={`${styles.table_text6} ${styles.table_text_header}`}
                  >
                    Improve
                  </th>
                  <th
                    className={`${styles.table_text4} ${styles.table_text_header}`}
                  >
                    Action for tomorrow
                  </th>
                  <th
                    className={`${styles.table_text4} ${styles.table_text_header}`}
                  >
                    Action for tomorrow
                  </th>
                  <th
                    className={`${styles.table_text4} ${styles.table_text_header}`}
                  >
                    Action for tomorrow
                  </th>
                </tr>
              </thead>
              <tbody>
                {cohortFeedbacks?.feedbacks.map(
                  (feedback: ICohortFeedback, index) => {
                    return (
                      <tr key={index}>
                        <td className={styles.table_text2}>{feedback.name}</td>
                        <td className={styles.table_text4}>{feedback.email}</td>
                        <td className={styles.table_num}>
                          {feedback.relevance}
                        </td>
                        <td className={styles.table_num}>
                          {feedback.objectives}
                        </td>
                        <td className={styles.table_num}>{feedback.pace}</td>
                        <td className={styles.table_num}>
                          {feedback.duration}
                        </td>
                        <td className={styles.table_num}>
                          {feedback.material}
                        </td>
                        <td className={styles.table_num}>{feedback.labs}</td>
                        <td className={styles.table_num}>
                          {feedback.instructor}
                        </td>
                        <td className={styles.table_num}>
                          {feedback.presentation}
                        </td>
                        <td className={styles.table_num}>{feedback.overall}</td>
                        <td className={styles.table_num}>{feedback.promote}</td>
                        <td className={styles.table_text6}>{feedback.like}</td>
                        <td className={styles.table_text6}>
                          {feedback.improve}
                        </td>
                        <td className={styles.table_text4}>
                          {feedback.action1}
                        </td>
                        <td className={styles.table_text4}>
                          {feedback.action2}
                        </td>
                        <td className={styles.table_text4}>
                          {feedback.action3}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Loading>
    </>
  );
}
