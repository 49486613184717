import { ReactElement } from "react";
import UserMessages from "./UserMessages";
import { IUserInfo } from "../../messages/IAppInfo";
import DashboardStatistics from "./DashboardStatistics";

export default function Dashboard(props: {
    userInfo: IUserInfo;
}): ReactElement {
    return (
        <>
            <div className="cy__dashboard">
                <DashboardStatistics userId={props.userInfo.userId} />

                <UserMessages userId={props.userInfo.userId} />
            </div>
        </>
    );
}