export function redirectTo(location: string) {
  // TODO: use the following code if a path is implemented in React,
  // otherwise all of the resources of the application will be downloaded again

  // import { useNavigate } from "react-router-dom";
  // const navigate = useNavigate();
  // navigate(location);

  window.location.href = location;
}

export function redirectToLoginWithNext(nextUrl: string) {
  redirectTo(`/accounts/login?next=${encodeURIComponent(nextUrl)}`);
}

export function redirectToLoginWithCurrentAsNext() {
  redirectToLoginWithNext(
    `${window.location.pathname}${window.location.search}`
  );
}
