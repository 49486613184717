import { ReactElement, ReactNode } from "react";
import styles from "./Loading.module.css";

export default function Loading(props: {
  isLoading: boolean;
  children: ReactNode;
}): ReactElement {
  return (
    <>
      {props.isLoading ? (
        <>
          <p></p>
          <div className={styles.loader}>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      ) : (
        props.children
      )}
    </>
  );
}
