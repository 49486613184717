import {
  FormEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import useApiState, { api } from "../../hooks/use-api-state";
import ErrorNotification from "../common/ErrorNotification";
import Loading from "../common/Loading";
import PartnerOrganizationSelector from "./PartnerOrganizationSelector";
import styles from "./AccessTokens.module.css";
import { IPartnerOrganization } from "../../model/IPartnerOrganization";
import NotificationsContext from "../../store/notifications-context";
import SidebarLayout from "../common/SidebarLayout";
import { useSearchParams } from "react-router-dom";

export default function PartnerOrganizationManagement(): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();

  const [getPartnerOrganizationTokenApiState, setGetPartnerOrganizationTokenApiState] = useApiState(false);
  const notificationsContext = useContext(NotificationsContext);

  const [selectedPartnerOrganization, setSelectedPartnerOrganization] =
    useState<IPartnerOrganization | null>(null);
  const [selectedExpirationTimeInDays, setSelectedExpirationTimeInDays] =
    useState<number>(0);

  const [requestedPartnerOrganization, setRequestedPartnerOrganization] =
    useState<IPartnerOrganization | null>(null);
  const [requestedExpirationTimeInDays, setRequestedExpirationTimeInDays] =
    useState<number>(93);

  const [generatedAccessToken, setGeneratedAccessToken] = useState<string>("");

  function copyAccessToken() {
    if (generatedAccessToken.length !== 0) {
      navigator.clipboard.writeText(generatedAccessToken);
      notificationsContext.addNotification({
        message: "Access token copied to the clipboard",
      });
    } else {
      notificationsContext.addNotification({
        message: "There is no access token to be copied to the clipboard",
      });
    }
  }

  async function submitHandler(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (selectedPartnerOrganization !== null) {
      setGeneratedAccessToken("");

      setRequestedPartnerOrganization(selectedPartnerOrganization);
      setRequestedExpirationTimeInDays(selectedExpirationTimeInDays);

      try {
        const accessToken =
          await api.getAccessTokenForPartnerOganization(
            setGetPartnerOrganizationTokenApiState,
            selectedPartnerOrganization.id,
            selectedExpirationTimeInDays
          );
        if (accessToken != null) {
          setGeneratedAccessToken(accessToken);
        }
      } catch (e: any) { }
    }
  }

  function expirationTimeInDaysChangeHandler(
    event: FormEvent<HTMLInputElement>
  ) {
    let sp = new URLSearchParams(searchParams);
    if (event.currentTarget.value !== "") {
      sp.set("expiration-time-in-days", event.currentTarget.value);
    } else {
      sp.delete("expiration-time-in-days");
    }
    setSearchParams(sp);
  }

  const partnerOrganizationSelected = useCallback(
    function (
      partnerOrganization: IPartnerOrganization | null
    ) {
      setSelectedPartnerOrganization(partnerOrganization);
    }, []);

  useEffect(() => {
    let expirationTimeInDays = searchParams.get("expiration-time-in-days");
    if (expirationTimeInDays !== null) {
      setSelectedExpirationTimeInDays(+expirationTimeInDays);
    } else {
      let sp = new URLSearchParams(searchParams);
      sp.set("expiration-time-in-days", "90");
      setSearchParams(sp, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  const isSubmitButtonEnabled =
    selectedPartnerOrganization !== null && selectedExpirationTimeInDays > 0;

  return (
    <>
      <div className="cy__dashboard__forms">
        <SidebarLayout
          keyForStorage="accessTokens"
          minimizedTitle="Access tokens"
          sidebarContent={
            <form onSubmit={submitHandler}>
              <div className={styles.column}>
                <PartnerOrganizationSelector
                  required
                  onPartnerOrganizationSelected={partnerOrganizationSelected}
                />

                <div className={styles.row}>
                  <label htmlFor="expiration-time-days">
                    Expiration time in days
                  </label>
                  <input
                    type="number"
                    id="expiration-time-days"
                    min="1"
                    max="365"
                    step="1"
                    value={selectedExpirationTimeInDays}
                    onChange={expirationTimeInDaysChangeHandler}
                    required
                  />
                </div>

                <div>
                  <Loading isLoading={getPartnerOrganizationTokenApiState.isLoading}>
                    <button
                      type="submit"
                      className="btn primary wide"
                      disabled={!isSubmitButtonEnabled}
                    >
                      Generate
                    </button>

                    <ErrorNotification
                      errorMessage={getPartnerOrganizationTokenApiState.errorMessage}
                    />
                  </Loading>
                </div>
              </div>
            </form>
          }
          mainContent={
            <div className="cy__forms">
              {generatedAccessToken !== "" && (
                <>
                  <h1>
                    Generated access token for{" "}
                    {requestedPartnerOrganization?.name}
                  </h1>

                  <button
                    className="btn primary narrow"
                    onClick={copyAccessToken}
                  >
                    Put access token to clipboard
                  </button>

                  <h3>Expiration time: {requestedExpirationTimeInDays} days</h3>

                  <div className={styles.generated_access_token}>
                    {generatedAccessToken}
                  </div>
                </>
              )}
            </div>
          }
        />
      </div>
    </>
  );
}
