import { FormEvent, ReactElement, useState } from "react";
import useApiState, { api } from "../../hooks/use-api-state";
import useInput from "../../hooks/use-input";
import { redirectToLoginWithNext } from "../../utils/redirect";
import PasswordInput from "../common/PaswordInput";
import ErrorNotification from "../common/ErrorNotification";
import Loading from "../common/Loading";

export default function PasswordChange(): ReactElement {
  const [apiState, setApiState] = useApiState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const currentPasswordInput = useInput(
    "",
    (value: string): boolean => {
      return value.length > 0;
    },
    "cy__err"
  );
  const passwordInput = useInput(
    "",
    (value: string): boolean => {
      return value.length >= 9;
    },
    "cy__err"
  );
  const passwordRepeatInput = useInput(
    "",
    (value: string): boolean => {
      return passwordInput.value === value;
    },
    "cy__err"
  );

  const currentPasswordErrorClass = currentPasswordInput.hasError
    ? "cy__err"
    : "";
  const currentPasswordErrorMessage = currentPasswordInput.hasError
    ? "Your current password cannot be empty"
    : "";

  const passwordRepeatErrorClass = passwordRepeatInput.hasError
    ? "cy__err"
    : "";
  const passwordRepeatErrorMessage = passwordRepeatInput.hasError
    ? "The passwords do not match"
    : "";

  async function submitHandler(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const ret = await api.changePassword(
      setApiState,
      currentPasswordInput.value,
      passwordInput.value
    );
    if (ret !== null) {
      if (ret.status==="OK") {
        setPasswordErrorMessage("");
        redirectToLoginWithNext("/");
      } else if (ret.status==="WEAK") {
        setPasswordErrorMessage(ret.message);
      }
    }
  }


  async function passwordBlurHandler(
    event:
      | React.FormEvent<HTMLInputElement | HTMLSelectElement>
      | React.FocusEvent<HTMLTextAreaElement>
      | React.FormEvent<HTMLSelectElement>
  ) {
    //On focus change, remove any previous global error messages received from the server
    apiState.errorMessage = "";

    api.checkPassword(
      passwordInput.value,
      {setErrorMessage:setPasswordErrorMessage}
    ).then((ret) => {});

    passwordInput.inputBlurHandler(event);
  }


  return (
    <>
      <div className="cy__login">
        <div className="login__flex">
          <h1>Change your password</h1>
          <form onSubmit={submitHandler} autoComplete="off">
            <div>
              <PasswordInput
                label="Current password"
                name="current-password"
                disabled={false}
                className={`${currentPasswordErrorClass}`}
                autoComplete="current-password"
                value={currentPasswordInput.value}
                onChange={currentPasswordInput.valueChangeHandler}
                onBlur={currentPasswordInput.inputBlurHandler}
              />
              {!!currentPasswordErrorMessage && (
                <p className="cy__err">{currentPasswordErrorMessage}</p>
              )}
            </div>

            <div className="divider"></div>

            <div>
              <PasswordInput
                label="New password"
                name="password"
                disabled={false}
                className={`${passwordErrorMessage ? 'cy__err' : ''}`}
                autoComplete="new-password"
                value={passwordInput.value}
                onChange={passwordInput.valueChangeHandler}
                onBlur={passwordBlurHandler}
              />
              {!!passwordErrorMessage && (
                <p className="cy__err">{passwordErrorMessage}</p>
              )}
            </div>

            <div>
              <PasswordInput
                label="Repeat your new password"
                name="password-repeated"
                disabled={false}
                className={`${passwordRepeatErrorClass}`}
                autoComplete="new-password"
                value={passwordRepeatInput.value}
                onChange={passwordRepeatInput.valueChangeHandler}
                onBlur={passwordRepeatInput.inputBlurHandler}
              />
              {!passwordErrorMessage && !!passwordRepeatErrorMessage && (
                <p className="cy__err">{passwordRepeatErrorMessage}</p>
              )}
            </div>

            {!!apiState.errorMessage && (
              <ErrorNotification errorMessage={apiState.errorMessage} />
            )}

            <div>
              <button
                type="submit"
                className="btn wide primary"
                disabled={
                  !( currentPasswordInput.isValid &&
                     passwordErrorMessage==="" &&
                     passwordRepeatInput.isValid )
                }
                >
                <Loading isLoading={apiState.isLoading}>Change password</Loading>
              </button>
            </div>
          </form>
          <br />
          <button
            className="btn wide secondary"
            onClick={(e) => {
              e.preventDefault();
              window.location.href='/';
            }}>
              Back
          </button>
        </div>
      </div>
    </>
  );
}
