import { ReactElement} from "react";
import styles from "./TinyProgressBar.module.css";


export default function TinyProgressBar(props: {
  percentage: number,
  status: string | null,
}): ReactElement {
  //console.log(`*** TinyProgressBar: ${JSON.stringify(props)}`);
  return (
    <div className={styles.progressbar}>
      <div className={styles.label}>
          <div className={styles.status}>{props.status??""}</div>
          <div className={styles.percentage}>{props.percentage}%</div>
      </div>
      <div className={styles.progress}>
          <span className={styles.line} style={{width: props.percentage+'%'}}></span>
      </div>
    </div>
  );
}