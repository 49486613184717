import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { redirectToLoginWithCurrentAsNext } from "../../utils/redirect";
import { IUserInfo } from "../../messages/IAppInfo";


export interface IPermission {
  permission: string,
  resource?: string | null,
}


//Checks if the user has a permission
//- resource === undefined: at all / for any object (vertical authz, used to show/hide certain menu items)
//- resource !== undefined: for the specific resource
export function hasPermission(userInfo: IUserInfo | null, requiredPermissions: IPermission[]): boolean {
  let accessAllowed = false;
  if (userInfo && userInfo.permissions) {
    accessAllowed = true;
    for (const requiredPermission of requiredPermissions) {
      let userPermission: string[] = userInfo.permissions[requiredPermission.permission];

      if (userPermission === undefined) {
        accessAllowed = false;
        break;
      }

      if (requiredPermission.resource !== undefined && requiredPermission.resource !== null) {
        if (!userPermission.includes(requiredPermission.resource) && !userPermission.includes("*")) {
          accessAllowed = false;
          break;
        }
      }
    }
  }
  return accessAllowed;
}


// Same as above, but the logical relationship between the list element is OR!
// => it returns true if the user has ANY OF THE PERMISSIONS from the list (it's more handy this way)
export function hasAnyPermission(userInfo: IUserInfo | null, requiredPermissions: IPermission[]): boolean {
  let accessAllowed = false;
  if (userInfo && userInfo.permissions) {
    for (const requiredPermission of requiredPermissions) {
      let userPermission: string[] = userInfo.permissions[requiredPermission.permission];
      if (userPermission !== undefined) {
        if ((requiredPermission.resource === undefined || requiredPermission.resource === null) || // No required resouce, or
          userPermission.includes("*") || // Permission for any resource, or
          userPermission.includes(requiredPermission.resource)) { // Required resource matches users'
          accessAllowed = true;
          break;
        }
      }
    }
  }
  return accessAllowed;
}


export default function ProtectedRoute(props: {
  userInfo: IUserInfo | null;
  requiredPermissions?: IPermission[];
  requiredAnyPermissions?: IPermission[];
  children: ReactElement;
}): ReactElement {
  let accessAllowed = false;

  if (props.userInfo && (props.requiredPermissions === undefined || hasPermission(props.userInfo, props.requiredPermissions))) {
    accessAllowed = true;
  }

  if (props.userInfo && (props.requiredAnyPermissions === undefined || hasAnyPermission(props.userInfo, props.requiredAnyPermissions))) {
    accessAllowed = true;
  }

  if (props.userInfo === null) {
    redirectToLoginWithCurrentAsNext();
    return <></>;
  } else {
    return <>{accessAllowed ? <>{props.children}</> : <Navigate to={"/"} />}</>;
  }
}
