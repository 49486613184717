import { ReactElement } from "react";
import styles from "./Notification.module.css";
import { INotification } from "../../store/notifications-context";

export default function Notification(props: {
  notification: INotification;
}): ReactElement {
  return (
    <>
      <div className={styles.container}>{props.notification.message}</div>
    </>
  );
}
