import { FormEvent, ReactElement, useContext, useState } from "react";
import useApiState, { api } from "../../hooks/use-api-state";
import useInput from "../../hooks/use-input";
import NotificationsContext from "../../store/notifications-context";
import ErrorNotification, { Notification } from "../common/ErrorNotification";
import Loading from "../common/Loading";
import styles from "./ForgottenPassword.module.css";

export default function ForgottenPassword(): ReactElement {
  const notificationsContext = useContext(NotificationsContext);
  const [apiState, setApiState] = useApiState(false);
  const [emailSent, setEmailSent] = useState(false);
  const sentMessage = "If a user exists with the given e-mail address, then a message is sent with instructions on how to reset the password.";

  const emailInput = useInput(
    "",
    (value: string) => {
      return value.match(".+@.+\\..+") !== null;
    },
    "cy__err"
  );

  const emailInputErrorClass = emailInput.hasError ? "cy__err" : "";

  async function submitHandler(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    await api.triggerForgottenPassword(setApiState, emailInput.value);

    setEmailSent(true);

    notificationsContext.addNotification({
      message: sentMessage
    });
  }

  return (
    <>
      <div className="cy__login">
        <div className="login__flex forgotten__password">
          <h1>Recover your account</h1>
          <form onSubmit={submitHandler} autoComplete="off">
            <div>
              <label htmlFor="email">Please enter your e-mail address</label>
              <input
                className={`${emailInputErrorClass}`}
                value={emailInput.value}
                onChange={emailInput.valueChangeHandler}
                onBlur={emailInput.inputBlurHandler}
                type="email"
                id="email"
                placeholder="example@domain.com"
                required
                disabled={emailSent}
              />
            </div>

            {!!apiState.errorMessage && (
              <div>
                <br />
                <ErrorNotification errorMessage={apiState.errorMessage} />
              </div>
            )}

            <div className={styles.buttons_vertical}>
              {emailSent ? (
                <div>
                  <Notification message={sentMessage} />
                </div>
              ) : (
                <div>
                  <Loading isLoading={apiState.isLoading}>
                    <button
                      type="submit"
                      className="btn wide primary"
                      disabled={!emailInput.isValid}>
                      Reset password
                    </button>
                  </Loading>
                </div>
              )}
              <div>
                <br />
                <button
                  className="btn wide secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = '/';
                  }}>
                  Back
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </>
  );
}
