import {
  FormEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import useApiState, { api } from "../../hooks/use-api-state";
import { IPartnerOrganizationListResponse } from "../../messages/IPartnerOrganizationListResponse";
import { IPartnerOrganization } from "../../model/IPartnerOrganization";
import ErrorNotification from "../common/ErrorNotification";
import Loading from "../common/Loading";
import styles from "./PartnerOrganizationSelector.module.css";

export default function PartnerOrganizationSelector(props: {
  required: boolean;
  onPartnerOrganizationSelected: (
    partnerOrganization: IPartnerOrganization | null
  ) => void;
}): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const [getPartnerOrganizationListApiState, setGetPartnerOrganizationListApiState] = useApiState(true);
  const [partnerOrganizationList, setPartnerOrganizationList] =
    useState<IPartnerOrganizationListResponse | null>(null);
  const [selectedPartnerOrganizationId, setSelectedPartnerOrganizationId] =
    useState<string | null>(null);
  const [
    isPartnerOrganizationSelectTouched,
    setIsPartnerOrganizationSelectTouched,
  ] = useState(false);

  const partnerOrganizationIdValue =
    selectedPartnerOrganizationId === null ? "" : selectedPartnerOrganizationId;

  function partnerOrganizationBlurHandler(event: FormEvent<HTMLSelectElement>) {
    setIsPartnerOrganizationSelectTouched(true);
  }

  function partnerOrganizationChangeHandler(
    event: FormEvent<HTMLSelectElement>
  ) {
    setIsPartnerOrganizationSelectTouched(true);

    let sp = new URLSearchParams(searchParams);
    if (event.currentTarget.value === "") {
      sp.delete("partner-organization-id");
    } else {
      sp.set("partner-organization-id", event.currentTarget.value);
    }
    setSearchParams(sp);
  }

  let fetchCallback = useCallback(async function () {
    try {
      let ret =
        await api.getPartnerOrganizationList(setGetPartnerOrganizationListApiState);
      setPartnerOrganizationList(ret);
    } catch (e: any) { }
  }, [setGetPartnerOrganizationListApiState]);

  useEffect(
    function () {
      let partnerOrganizationIdInSearchParams = searchParams.get(
        "partner-organization-id"
      );

      if (partnerOrganizationIdInSearchParams) {
        if (partnerOrganizationList) {
          const partnerOrganizationFoundInList =
            partnerOrganizationList.partner_organizations.find(
              (partner_organization: IPartnerOrganization) => {
                return (
                  partner_organization.id ===
                  partnerOrganizationIdInSearchParams
                );
              }
            );

          if (partnerOrganizationFoundInList) {
            setSelectedPartnerOrganizationId(
              partnerOrganizationIdInSearchParams
            );
            props.onPartnerOrganizationSelected(partnerOrganizationFoundInList);
          } else {
            let sp = new URLSearchParams(searchParams);
            sp.delete("partner-organization-id");
            setSearchParams(sp);
          }
        }
      } else {
        setSelectedPartnerOrganizationId(null);
        props.onPartnerOrganizationSelected(null);
      }
    },
    [props, searchParams, partnerOrganizationList, setSearchParams]
  );

  useEffect(() => {
    fetchCallback();
  }, [fetchCallback]);

  const partnerOrganizationInputErrorClass =
    isPartnerOrganizationSelectTouched &&
      selectedPartnerOrganizationId === null &&
      props.required
      ? "cy__err"
      : "";

  return (
    <>
      <Loading isLoading={getPartnerOrganizationListApiState.isLoading}>
        <ErrorNotification
          errorMessage={getPartnerOrganizationListApiState.errorMessage}
        />

        <div className={styles.row}>
          <div>
            <label htmlFor="partner-organization">Partner organization:</label>
          </div>
          <div>
            <select
              id="partner-organization"
              className={`${partnerOrganizationInputErrorClass}`}
              value={partnerOrganizationIdValue}
              onChange={partnerOrganizationChangeHandler}
              onBlur={partnerOrganizationBlurHandler}
              required={props.required}
            >
              <option value="">Choose one</option>
              {partnerOrganizationList?.partner_organizations.map(
                (partnerOrganization: IPartnerOrganization) => {
                  return (
                    <option
                      key={partnerOrganization.id}
                      value={partnerOrganization.id}
                    >
                      {partnerOrganization.name}
                    </option>
                  );
                }
              )}
            </select>
          </div>
        </div>
      </Loading>
    </>
  );
}
