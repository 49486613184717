import { FormEvent, ReactElement } from "react";
import { CohortFormInput } from "../../../hooks/use-cohort-form";
import { IUserInfo } from "../../../messages/IAppInfo";
import { hasPermission } from "../../common/ProtectedRoute";
import ErrorNotification from "../../common/ErrorNotification";
import Loading from "../../common/Loading";
import styles from "./CohortForm.module.css";
import useApiState, { ApiStateSetter } from "../../../hooks/use-api-state";

export default function CohortForm(props: {
  userInfo: IUserInfo;
  cohortId: string | null;
  cohortFormInput: CohortFormInput;
  onSubmit: (setApiState: ApiStateSetter) => void;
  children: ReactElement;
}): ReactElement {
  const [apiState, setApiState] = useApiState(false);
  const cohortFormInput = props.cohortFormInput;
  const isInputDisabled = !hasPermission(props.userInfo, [
    { permission: "cohort_write", resource: props.cohortId }
  ]);

  async function submitHandler(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    props.onSubmit(setApiState);
  }

  return (
    <>
      <div className="cy__forms">
        <form onSubmit={submitHandler}>
          <div className={styles.container}>
            <div className={styles.row}>
              <div className={styles.form_label}>
                <label
                  className="required"
                  htmlFor="cohortCode"
                  title="Should be the same as the corresponding folder name!"
                >
                  Cohort code
                </label>
              </div>
              <div className={styles.col_stretch}>
                <input
                  className={`${styles.form_input} ${cohortFormInput.cohortCode.errorClass}`}
                  value={cohortFormInput.cohortCode.value}
                  onChange={cohortFormInput.cohortCode.valueChangeHandler}
                  onBlur={cohortFormInput.cohortCode.inputBlurHandler}
                  type="text"
                  disabled={isInputDisabled}
                  id="cohortCode"
                  required
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.form_label}>
                <label className="required" htmlFor="trainingTitle">
                  Training title
                </label>
              </div>
              <div className={styles.col_stretch}>
                <textarea
                  className={`${styles.form_input} ${cohortFormInput.trainingTitle.errorClass}`}
                  value={cohortFormInput.trainingTitle.value}
                  onChange={cohortFormInput.trainingTitle.valueChangeHandler}
                  onBlur={cohortFormInput.trainingTitle.inputBlurHandler}
                  name="Text1"
                  disabled={isInputDisabled}
                  id="trainingTitle"
                  rows={3}
                  required
                ></textarea>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.form_label}>
                <label className="required" htmlFor="topic">
                  Training topic
                </label>
              </div>
              <div className={styles.col_stretch}>
                <input
                  className={`${styles.form_input} ${cohortFormInput.topic.errorClass}`}
                  value={cohortFormInput.topic.value}
                  onChange={cohortFormInput.topic.valueChangeHandler}
                  onBlur={cohortFormInput.topic.inputBlurHandler}
                  type="text"
                  disabled={isInputDisabled}
                  id="topic"
                  required
                ></input>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.form_label}>
                <label className="required" htmlFor="trainerName">
                  Trainer name
                </label>
              </div>
              <div className={styles.col_stretch}>
                <input
                  className={`${styles.form_input} ${cohortFormInput.trainerName.errorClass}`}
                  value={cohortFormInput.trainerName.value}
                  onChange={cohortFormInput.trainerName.valueChangeHandler}
                  onBlur={cohortFormInput.trainerName.inputBlurHandler}
                  type="text"
                  disabled={isInputDisabled}
                  id="trainerName"
                  required
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.form_label}>
                <label className="required" htmlFor="companyName">
                  Company name
                </label>
              </div>
              <div className={styles.col_stretch}>
                <input
                  className={`${styles.form_input} ${cohortFormInput.companyName.errorClass}`}
                  value={cohortFormInput.companyName.value}
                  onChange={cohortFormInput.companyName.valueChangeHandler}
                  onBlur={cohortFormInput.companyName.inputBlurHandler}
                  type="text"
                  disabled={isInputDisabled}
                  id="companyName"
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.form_label}>
                <label className="" htmlFor="countryCode">
                  Country code
                </label>
              </div>
              <div className={styles.col_stretch}>
                <input
                  className={`${styles.form_input} ${cohortFormInput.countryCode.errorClass}`}
                  value={cohortFormInput.countryCode.value}
                  onChange={cohortFormInput.countryCode.valueChangeHandler}
                  onBlur={cohortFormInput.countryCode.inputBlurHandler}
                  type="text"
                  disabled={isInputDisabled}
                  id="countryCode"
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.form_label}>
                <label className="required" htmlFor="startDate">
                  Start date
                </label>
              </div>
              <div>
                <input
                  className={`${styles.form_input} ${styles.date_input} ${cohortFormInput.startDate.errorClass}`}
                  value={cohortFormInput.startDate.value}
                  onChange={cohortFormInput.startDate.valueChangeHandler}
                  onBlur={cohortFormInput.startDate.inputBlurHandler}
                  type="date"
                  disabled={isInputDisabled}
                  id="startDate"
                  required
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.form_label}>
                <label className="required" htmlFor="endDate">
                  End date
                </label>
              </div>
              <div>
                <input
                  className={`${styles.form_input} ${styles.date_input} ${cohortFormInput.endDate.errorClass}`}
                  value={cohortFormInput.endDate.value}
                  onChange={cohortFormInput.endDate.valueChangeHandler}
                  onBlur={cohortFormInput.endDate.inputBlurHandler}
                  type="date"
                  disabled={isInputDisabled}
                  id="endDate"
                  required
                />
                <input
                  className={`${styles.form_input} ${styles.timeZone} ${cohortFormInput.timeZone.errorClass}`}
                  value={cohortFormInput.timeZone.value}
                  onChange={cohortFormInput.timeZone.valueChangeHandler}
                  onBlur={cohortFormInput.timeZone.inputBlurHandler}
                  type="text"
                  disabled={isInputDisabled}
                  id="timeZone"
                  required
                />
                <input
                  className={`${styles.form_input} ${styles.timeInput} ${cohortFormInput.startTime.errorClass}`}
                  value={cohortFormInput.startTime.value}
                  onChange={cohortFormInput.startTime.valueChangeHandler}
                  onBlur={cohortFormInput.startTime.inputBlurHandler}
                  type="time"
                  pattern="[0-9]{2}:[0-9]{2}"
                  disabled={isInputDisabled}
                  id="startTime"
                  required
                />
                <input
                  className={`${styles.form_input} ${styles.timeInput} ${cohortFormInput.endTime.errorClass}`}
                  value={cohortFormInput.endTime.value}
                  onChange={cohortFormInput.endTime.valueChangeHandler}
                  onBlur={cohortFormInput.endTime.inputBlurHandler}
                  type="time"
                  pattern="[0-9]{2}:[0-9]{2}"
                  disabled={isInputDisabled}
                  id="endTime"
                  required
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.form_label}>
                <label className="required" htmlFor="expiresAfterDays"
                  title="The accounts added to this cohort expire in these many days after the class is over (the End date)" >
                  Expires after days
                </label>
              </div>
              <div>
                <input
                  className={`${styles.form_input} ${cohortFormInput.expiresAfterDays.errorClass}`}
                  value={cohortFormInput.expiresAfterDays.value}
                  onChange={cohortFormInput.expiresAfterDays.valueChangeHandler}
                  onBlur={cohortFormInput.expiresAfterDays.inputBlurHandler}
                  type="text"
                  disabled={isInputDisabled}
                  id="expiresAfterDays"
                  required
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.form_label}>
                <label className="required" htmlFor="sergeantVersion">
                  Sergeant version
                </label>
              </div>
              <div className={styles.col_stretch}>
                <input
                  className={`${styles.form_input} ${cohortFormInput.sergeantVersion.errorClass}`}
                  value={cohortFormInput.sergeantVersion.value}
                  onChange={cohortFormInput.sergeantVersion.valueChangeHandler}
                  onBlur={cohortFormInput.sergeantVersion.inputBlurHandler}
                  type="text"
                  disabled={isInputDisabled}
                  id="sergeantVersion"
                  required
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.form_label}>
                <label className="" htmlFor="attendantTemplateJsonFilename">
                  Attendant Template Json Filename
                </label>
              </div>
              <div className={styles.col_stretch}>
                <input
                  className={`${styles.form_input} ${cohortFormInput.attendantTemplateJsonFilename.errorClass}`}
                  value={cohortFormInput.attendantTemplateJsonFilename.value}
                  onChange={cohortFormInput.attendantTemplateJsonFilename.valueChangeHandler}
                  onBlur={cohortFormInput.attendantTemplateJsonFilename.inputBlurHandler}
                  type="text"
                  disabled={isInputDisabled}
                  id="attendantTemplateJsonFilename"
                />
              </div>
            </div>

            {props.children}

            {!isInputDisabled ? (
              <div className={styles.row}>
                <Loading isLoading={apiState.isLoading}>
                  <button
                    type="submit"
                    className="btn primary wide"
                    disabled={!cohortFormInput.isValid}
                  >
                    Save
                  </button>
                </Loading>
              </div>
            ) : null}

            <div className={styles.row}>
              {!!apiState.errorMessage && (
                <ErrorNotification errorMessage={apiState.errorMessage} />
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
