import { MouseEvent, ReactElement, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IAppInfo, IUserInfo } from "../../messages/IAppInfo";
import { redirectTo } from "../../utils/redirect";
import Notifications from "./Notifications";
import styles from "./Layout.module.css";
import AdminNavigationItems from "../admin/NavigationItems";
import UserSpaceNavigationItems from "../user-space/NavigationItems";
import { NavLink, useNavigate } from "react-router-dom";

export default function Layout(props: {
  isAdminLayout: boolean;
  appInfo: IAppInfo;
  userInfo: IUserInfo | null;
  children: ReactNode;
  onLogout: () => void;
}): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();

  /* Simple top reload - not necessarily/only against clickjacking, but due to any in-frame redirection (that we e.g. do on expired sessions in elcontent)
      Nevertheless, here is how this can be done in a more React-like manner: https://vishnugopal.com/2020/05/10/useiframebuster-react-hook/
  */
  if (window.top && window.top.location.href!==window.location.href) window.top.location.href=window.location.href;

  function hideMobileNavigation() {
    for (let element of Object.values(
      document.getElementsByClassName("navbar-toggler")
    )) {
      element.classList.remove("is-active");
    }

    for (let element of Object.values(
      document.getElementsByTagName("header")
    )) {
      element.classList.remove("open");
    }

    for (let element of Object.values(document.getElementsByTagName("body"))) {
      element.classList.remove("lock");
    }

    if (document.getElementsByClassName("sidebar").length) {
      for (let element of Object.values(
        document.getElementsByClassName("sidebar__open")
      )) {
        element.classList.remove("lock");
      }
    }
  }

  useEffect(() => {
    hideMobileNavigation();
  }, [location]);

  function redirectToUserSpace() {
    return function (e: MouseEvent<HTMLImageElement>) {
      e.preventDefault();

      let userSpacePath = localStorage.getItem("last_user_space_path") ?? "/";
      if (userSpacePath === "/") {
        navigate(userSpacePath);
      } else {
        redirectTo(userSpacePath);
      }
    };
  }

  function redirectToAdminPage() {
    if (props.userInfo) {
      localStorage.setItem(`last_user_space_path`, window.location.pathname);
      if (props.userInfo.permissions && props.userInfo.permissions["cohort_read"]) {
        navigate("/admin");
      } else {
        navigate("/admin/password");
      }
    }
  }

  function navbarTogglerClickedHandler(e: MouseEvent<HTMLElement>) {
    e.currentTarget.classList.toggle("is-active");

    let wasHeaderOpen = true;
    for (let element of Object.values(
      document.getElementsByTagName("header")
    )) {
      wasHeaderOpen = element.classList.contains("open");
      element.classList.toggle("open");
    }

    for (let element of Object.values(document.getElementsByTagName("body"))) {
      element.classList.toggle("lock");
    }

    if (wasHeaderOpen) {
      // reset sidebar
      for (let element of Object.values(
        document.getElementsByClassName("sidebar__open")
      )) {
        element.classList.remove("hidden");
      }
      for (let element of Object.values(
        document.getElementsByClassName("sidebar")
      )) {
        element.classList.remove("open");
      }
    } else {
      // hide sidebar and its toggler
      for (let element of Object.values(
        document.getElementsByClassName("sidebar__open")
      )) {
        element.classList.add("hidden");
      }
      for (let element of Object.values(
        document.getElementsByClassName("sidebar")
      )) {
        element.classList.remove("open");
      }
    }
  }

  function logoutClickedHandler(e: MouseEvent<HTMLElement>) {
    props.onLogout();
  }

  const navigationItems = props.isAdminLayout
    ?
    <AdminNavigationItems userInfo={props.userInfo} />
    :
    <UserSpaceNavigationItems userInfo={props.userInfo} />;

  return (
    <>
      <header>
        { <>
            <div className="h__logo">
              <NavLink to="/">
                <img
                  src="/static/img/cydrill_sergeant.png"
                  alt="Cydrill Sergeant logo"
                />
              </NavLink>
              { props.appInfo.instanceType!=="Production" &&
                  <div className={styles.admin_space_text}>
                    {props.appInfo.instanceType} (db:&nbsp;<span className={props.appInfo.databaseEnvironmentType==="RELEASE" ? styles.dbtype_release_text : ""}>
                      {props.appInfo.databaseEnvironmentType}</span>)
                  </div>
              }
            </div>
          </>
        }
        <div className="h__flex">
          <ul className="h__nav">
            {navigationItems}
          </ul>

          {props.userInfo !== null && (
            <>
              <div className="h__login">
                {
                  props.isAdminLayout ?
                    <>
                      <img
                        className="img spaceswitcher"
                        src="/static/img/user_120x120_2.png"
                        alt="Back to user space"
                        title="Back to user space"
                        onClick={redirectToUserSpace()}
                      />
                    </> : <>
                      <img
                        className="img spaceswitcher"
                        src="/static/img/settings_120x120_2.png"
                        alt="Settings"
                        title="Settings"
                        onClick={redirectToAdminPage}
                      />
                    </>
                }

                <div title={"Signed in as\n" + props.userInfo.fullname + "\n(" + props.userInfo.email + ")"}>
                  <button
                    className="btn narrow primary login"
                    onClick={logoutClickedHandler}
                  >
                    Log out
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <button
          className="navbar-toggler hamburger hamburger--collapse"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-controls="navigation"
          aria-expanded="false"
          aria-label="Menu"
          onClick={navbarTogglerClickedHandler}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </header>
      <div className="mobile__menu">
        {props.userInfo !== null && (
          <>
            <p>
              <span>Signed in as</span>
              <span>{props.userInfo.email}</span>
            </p>
          </>
        )}
        <nav>
          <ul className="h__nav">
            {navigationItems}
          </ul>
        </nav>

        {
          props.isAdminLayout ? <>
            <NavLink to="/">
              <img
                className="img spaceswitcher"
                src="/static/img/user_120x120_2.png"
                alt="Back to user space"
                title="Back to user space"
                onClick={redirectToUserSpace()}
              />
            </NavLink>
          </> : <>
            <img
              className="img spaceswitcher"
              src="/static/img/settings_120x120_2.png"
              alt="Settings"
              title="Settings"
              onClick={redirectToAdminPage}
            />
          </>
        }
        <button
          className="btn narrow primary login-mobile"
          onClick={logoutClickedHandler}
        >
          Log out
        </button>
      </div>

      <main role="main">{props.children}</main>

      <footer>
        <div className="f__flex">
          <div className="f__logo">
            <img
              src="/static/img/cydrill_sergeant.png"
              alt="Cydrill Sergeant logo"
            />
          </div>

          <ul className="f__nav">
            {navigationItems}
          </ul>

          <ul className="f__social">
            <li>
              <a target="__blank" href="https://www.facebook.com/cydrill">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a
                target="__blank"
                href="https://www.linkedin.com/company/cydrill/about/"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="f__sub">
          <ul>
            <li>
              <a
                href="https://cydrill.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </a>
            </li>
            <li>
              <a target="__blank" href="https://cydrill.com/">
                www.cydrill.com
              </a>
            </li>
          </ul>
        </div>
      </footer>

      <div id="modal-root" />
      <Notifications />
    </>
  );
}
