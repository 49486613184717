import { ReactElement, useCallback } from "react";
import { ApiStateSetter, api } from "../../../hooks/use-api-state";
import useCohortForm from "../../../hooks/use-cohort-form";
import { IGetCohortByIdResponse } from "../../../messages/IGetCohortByIdResponse";
import {
  convertSecondsSinceEpochToDateInputString,
  getSecondsSinceEpochFromDate,
} from "../../../utils/time";
import { IUserInfo } from "../../../messages/IAppInfo";
import CohortForm from "./CohortForm";
import styles from "./CohortForm.module.css";

export default function CohortData(props: {
  userInfo: IUserInfo;
  cohort: IGetCohortByIdResponse;
  onCohortEdited: () => void;
}): ReactElement {
  const cohortFormInput = useCohortForm(
    "cy__err",
    props.cohort.title,
    props.cohort.topic,
    props.cohort.code,
    props.cohort.trainername,
    props.cohort.companyname,
    props.cohort.countrycode,
    convertSecondsSinceEpochToDateInputString(props.cohort.startdate_secs_utc),
    convertSecondsSinceEpochToDateInputString(props.cohort.enddate_secs_utc),
    props.cohort.timezone,
    props.cohort.start_time,
    props.cohort.end_time,
    (props.cohort.expires_after_days || "").toString(),
    props.cohort.sergeantversion,
    props.cohort.attendant_template_json_filename,
  );

  const onEditedHandler = useCallback(
    async function (setApiState: ApiStateSetter) {
      try {
        let startDateSeconds = getSecondsSinceEpochFromDate(
          new Date(cohortFormInput.startDate.value)
        );
        let endDateSeconds = getSecondsSinceEpochFromDate(
          new Date(cohortFormInput.endDate.value)
        );

        let expiresAfterDays = parseInt(cohortFormInput.expiresAfterDays.value, 10)

        await api.updateCohort(
          setApiState,
          props.cohort.id,
          props.cohort.title !== cohortFormInput.trainingTitle.value ? cohortFormInput.trainingTitle.value : null,
          props.cohort.topic !== cohortFormInput.topic.value ? cohortFormInput.topic.value : null,
          props.cohort.code !== cohortFormInput.cohortCode.value ? cohortFormInput.cohortCode.value : null,
          props.cohort.trainername !== cohortFormInput.trainerName.value ? cohortFormInput.trainerName.value : null,
          props.cohort.companyname !== cohortFormInput.companyName.value ? cohortFormInput.companyName.value : null,
          props.cohort.countrycode !== cohortFormInput.countryCode.value ? cohortFormInput.countryCode.value : null,
          props.cohort.startdate_secs_utc !== startDateSeconds ? startDateSeconds : null,
          props.cohort.enddate_secs_utc !== endDateSeconds ? endDateSeconds : null,
          props.cohort.timezone !== cohortFormInput.timeZone.value ? cohortFormInput.timeZone.value : null,
          props.cohort.start_time !== cohortFormInput.startTime.value ? cohortFormInput.startTime.value : null,
          props.cohort.end_time !== cohortFormInput.endTime.value ? cohortFormInput.endTime.value : null,
          props.cohort.expires_after_days !== expiresAfterDays ? expiresAfterDays : null,
          props.cohort.sergeantversion !== cohortFormInput.sergeantVersion.value
            ? cohortFormInput.sergeantVersion.value : null,
            props.cohort.attendant_template_json_filename !== cohortFormInput.attendantTemplateJsonFilename.value
            ? cohortFormInput.attendantTemplateJsonFilename.value : null,
        );

        props.onCohortEdited();
      } catch (e: any) { }
    }, [
      props,
      cohortFormInput.attendantTemplateJsonFilename.value,
      cohortFormInput.cohortCode.value,
      cohortFormInput.companyName.value,
      cohortFormInput.countryCode.value,
      cohortFormInput.startDate.value,
      cohortFormInput.endDate.value,
      cohortFormInput.timeZone.value,
      cohortFormInput.startTime.value,
      cohortFormInput.endTime.value,
      cohortFormInput.expiresAfterDays.value,
      cohortFormInput.sergeantVersion.value,
      cohortFormInput.topic.value,
      cohortFormInput.trainerName.value,
      cohortFormInput.trainingTitle.value,
    ]);

  return (
    <>
      <CohortForm userInfo={props.userInfo} cohortId={props.cohort.id} cohortFormInput={cohortFormInput} onSubmit={onEditedHandler}>
        <div>
          <div className={styles.row}>
            <div className={styles.form_label}>
              <p className={styles.label}>Eval form</p>
            </div>
            <div>
              <a
                href={`/feedback?id=${props.cohort.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to open
              </a>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.form_label}>
              <p className={styles.label}>Live status</p>
            </div>
            <div>
              <a
                href={`/livestatus/${props.cohort.sergeantversion}/${props.cohort.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to open
              </a>
            </div>
          </div>
          <br/>
        </div>
      </CohortForm>
    </>
  );
}
