import { FormEvent, ReactElement, useCallback, useEffect, useState } from "react";
import useApiState, { api } from "../../hooks/use-api-state";
import SidebarLayout from "../common/SidebarLayout";
import PartnerOrganizationSelector from "./PartnerOrganizationSelector";
import { IPartnerOrganization } from "../../model/IPartnerOrganization";
import Loading from "../common/Loading";
import { IPartnerOrganizationInvitation, IPartnerOrganizationInvitationListResponse } from "../../messages/IPartnerOrganizationInvitationListResponse";
import pluralize from "../../utils/pluralize";
import useInput from "../../hooks/use-input";
import ErrorNotification from "../common/ErrorNotification";
import { hasPermission } from "../common/ProtectedRoute";
import { IUserInfo } from "../../messages/IAppInfo";

export default function Invitations(props: {
  userInfo: IUserInfo,
}): ReactElement {
  const [apiState, setApiState] = useApiState(false);
  const [sendInvitationEmailApiState, setSendInvitationEmailApiState] = useApiState(false);

  const [selectedPartnerOrganization, setSelectedPartnerOrganization] = useState<IPartnerOrganization | null>(null);
  const [partnerOrganizationInvitations, setPartnerOrganizationInvitations] = useState<IPartnerOrganizationInvitationListResponse | null>(null);
  const [isTopicSelectTouched, setIsTopicSelectTouched] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);

  const emailInput = useInput(
    "",
    (value: string) => {
      return value.match(".+@.+\\..+") !== null;
    },
    "cy__err"
  );

  const emailInputErrorClass = emailInput.hasError ? "cy__err" : "";

  useEffect(() => {
    if (selectedPartnerOrganization !== null) {
      api
        .getInvitationsOfPartnerOrganization(
          setApiState,
          selectedPartnerOrganization.id)
        .then((invitations: IPartnerOrganizationInvitationListResponse) => {
          setPartnerOrganizationInvitations(invitations);
        });
    } else {
      setPartnerOrganizationInvitations(null);
    }
  }, [selectedPartnerOrganization, setApiState])

  const partnerOrganizationSelected = useCallback(
    function (
      partnerOrganization: IPartnerOrganization | null
    ) {
      setSelectedPartnerOrganization(partnerOrganization);
    }
    , []);

  async function submitHandler(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      await api.sendInvitationOfPartnerOrganization(
        setSendInvitationEmailApiState,
        selectedPartnerOrganization!.id,
        emailInput.value,
        selectedTopic!);
      emailInput.reset("");
    } catch (e: any) {
    }

    if (selectedPartnerOrganization !== null) {
      api
        .getInvitationsOfPartnerOrganization(
          setApiState,
          selectedPartnerOrganization.id)
        .then((invitations: IPartnerOrganizationInvitationListResponse) => {
          setPartnerOrganizationInvitations(invitations);
        });
    } else {
      setPartnerOrganizationInvitations(null);
    }
  }

  const topicInputErrorClass =
    isTopicSelectTouched &&
      selectedTopic === null
      ? "cy__err"
      : "";

  function topicBlurHandler(event: FormEvent<HTMLSelectElement>) {
    setIsTopicSelectTouched(true);
  }

  function topicChangeHandler(
    event: FormEvent<HTMLSelectElement>
  ) {
    setIsTopicSelectTouched(true);
    setSelectedTopic(event.currentTarget.value);
  }

  const selectedTopicValue =
    selectedTopic === null ? "" : selectedTopic;

  const isSendButtonEnabled = emailInput.isValid && selectedTopic !== null;

  const hasPermissionToSpendInvitation = props.userInfo === null ? false : hasPermission(props.userInfo, [
    { permission: "partner_organization_read" },
    { permission: "invitation_spend_of_allowed_partner_organizations" }
  ]);

  return (
    <>
      <div className="cy__dashboard__forms">
        <SidebarLayout
          keyForStorage="invitations"
          minimizedTitle="Partner organizations"
          sidebarContent={
            <div>
              <PartnerOrganizationSelector
                required
                onPartnerOrganizationSelected={partnerOrganizationSelected}
              />
            </div>
          }
          mainContent={
            <div className="cy__forms">
              <h1>Managing invitations</h1>
              <div className="flex-column flex-gap-05em">
                <Loading isLoading={apiState.isLoading}>
                  {partnerOrganizationInvitations !== null ?
                    <>
                      {hasPermissionToSpendInvitation &&
                        <>
                          <div className="flex-row flex-gap-05em"><h3>Unsent invitations</h3></div>

                          {partnerOrganizationInvitations.unspent_invitation_count === 0 ?
                            <>
                              <div className="flex-row flex-gap-05em">
                                You do not have any invitations
                              </div>
                            </>
                            :
                            <>
                              <div className="flex-row flex-gap-05em">
                                You have <b>{partnerOrganizationInvitations.unspent_invitation_count}</b> {pluralize("invitation", partnerOrganizationInvitations.unspent_invitation_count, "s")} to send
                              </div>
                              <div className="flex-row flex-gap-05em">
                                <form onSubmit={submitHandler}>
                                  <div className="cy__flex_container">
                                    <div className="cy__flex_row cy__flex_gap_15px">
                                      <div>
                                        <input
                                          className={`${emailInputErrorClass}`}
                                          value={emailInput.value}
                                          onChange={emailInput.valueChangeHandler}
                                          onBlur={emailInput.inputBlurHandler}
                                          type="email"
                                          id="email"
                                          placeholder="invitee@e.mail"
                                          required
                                        />
                                      </div>
                                      <div>
                                        <select
                                          id="topic"
                                          className={`${topicInputErrorClass}`}
                                          value={selectedTopicValue}
                                          onChange={topicChangeHandler}
                                          onBlur={topicBlurHandler}
                                          required
                                        >
                                          <option value="">Choose one</option>
                                          {
                                            Object.entries(selectedPartnerOrganization!.cohorts).map((topic_cohort_id_pair: [string, string]) => {
                                              return <option
                                                key={topic_cohort_id_pair[0]}
                                                value={topic_cohort_id_pair[0]}
                                              >
                                                {topic_cohort_id_pair[0]}
                                              </option>
                                            })
                                          }
                                        </select>
                                      </div>
                                      <div>
                                        <Loading isLoading={sendInvitationEmailApiState.isLoading}>
                                          <button
                                            type="submit"
                                            className={`btn secondary wide ${isSendButtonEnabled ? "" : "disabled"}`}
                                          >
                                            Send invitation
                                          </button>
                                        </Loading>
                                      </div>
                                      <div>
                                        {!!sendInvitationEmailApiState.errorMessage && (
                                          <ErrorNotification
                                            errorMessage={sendInvitationEmailApiState.errorMessage}
                                          ></ErrorNotification>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </>
                          }

                          <div className="divider"></div>
                        </>}

                      <div className="flex-row flex-gap-05em"><h3>Already sent invitations</h3></div>
                      {
                        partnerOrganizationInvitations.spent_invitations.map((invitation: IPartnerOrganizationInvitation, index) => {
                          if (invitation.email !== null && invitation.topic !== null) {
                            return <div className="flex-row flex-gap-05em" key={index}>
                              <form>
                                <div className="cy__flex_container">
                                  <div className="cy__flex_row cy__flex_gap_15px">
                                    <div>
                                      <input
                                        value={invitation.email}
                                        type="email"
                                        disabled
                                      />
                                    </div>
                                    <div>
                                      <select
                                        value={invitation.topic}
                                        disabled
                                      >
                                        <option value={invitation.topic}>{invitation.topic}</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>;
                          } else {
                            return <></>;
                          }
                        })
                      }

                    </>
                    :
                    <>
                      <div className="flex-row flex-gap-05em"><h3>Please select a partner organization!</h3></div>
                    </>
                  }
                </Loading>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
}
