import { useState } from "react";

export type UseInputHandler = {
  value: string;
  isValid: boolean;
  hasError: boolean;
  errorClass: string;
  valueChangeHandler: (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.FormEvent<HTMLTextAreaElement>
      | React.FormEvent<HTMLSelectElement>
  ) => void;
  changeValue: (value: string) => void;
  inputBlurHandler: (
    event:
      | React.FormEvent<HTMLInputElement | HTMLSelectElement>
      | React.FocusEvent<HTMLTextAreaElement>
      | React.FormEvent<HTMLSelectElement>
  ) => void;
  reset: (value: string) => void;
};

export default function useInput(
  defaultValue: string,
  validateValue: (value: string) => boolean,
  errorClass: string
): UseInputHandler {
  const [enteredValue, setEnteredValue] = useState<string>(defaultValue);
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const valueIsValid = validateValue(enteredValue);
  const hasError = !valueIsValid && isTouched;
  const generatedErrorClass = hasError ? errorClass : "";

  function changeValue(value: string) {
    setEnteredValue(value);
  }

  function valueChangeHandler(
    event:
      | React.FormEvent<HTMLInputElement>
      | React.FormEvent<HTMLTextAreaElement>
      | React.FormEvent<HTMLSelectElement>
  ) {
    changeValue(event.currentTarget.value);
  }

  function inputBlurHandler(
    event:
      | React.FormEvent<HTMLInputElement | HTMLSelectElement>
      | React.FocusEvent<HTMLTextAreaElement>
      | React.FormEvent<HTMLSelectElement>
  ) {
    setIsTouched(true);
  }

  function reset(value: string) {
    setEnteredValue(value);
    setIsTouched(false);
  }

  const ret: UseInputHandler = {
    value: enteredValue,
    isValid: valueIsValid,
    hasError: hasError,
    errorClass: generatedErrorClass,
    valueChangeHandler: valueChangeHandler,
    changeValue,
    inputBlurHandler: inputBlurHandler,
    reset: reset,
  };

  return ret;
}
