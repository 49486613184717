import { ReactElement } from "react";
import ReactDOM from "react-dom";

export default function ModalOverlay(props: {
  title: string;
  children: ReactElement;
  onCloseClick: () => void;
}): ReactElement {
  return (
    <>
      {ReactDOM.createPortal(
        <>
          <div className="modal__backdrop modal__shown">
            <div className="modal__wrapper">
              <div className="modal hasBackground">
                <div className="modal__header">
                  <button
                    className="modal__button"
                    onClick={props.onCloseClick}
                  >
                    <img src="/static/img/Ellipse.svg" alt="Modal exit" />
                    <img
                      className="modal__button__union"
                      src="/static/img/Union.svg"
                      alt="Modal exit union"
                    />
                  </button>
                </div>
                <div className="modal__title">
                  <h2 className="highlighted">{props.title}</h2>
                </div>
                <div className="modal__body">{props.children}</div>
              </div>
            </div>
          </div>
        </>,
        document.getElementById("modal-root")!
      )}
    </>
  );
}
