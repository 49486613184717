import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFound from "./components/common/NotFound";
import PartnerOrganizationManagement from "./components/admin/AccessTokens";
import Layout from "./components/common/Layout";
import Register from "./components/admin/Register";
import Home from "./components/admin/Home";
import useApiState, { api } from "./hooks/use-api-state";
import { IAppInfo } from "./messages/IAppInfo";
import { useCallback, useEffect, useState } from "react";
import Loading from "./components/common/Loading";
import ErrorNotification from "./components/common/ErrorNotification";
import { redirectTo } from "./utils/redirect";
import { ILogoutResponse } from "./messages/IResponse";
import CohortManagement from "./components/admin/cohort-management/CohortManagement";
import PasswordChange from "./components/admin/PasswordChange";
import ForgottenPassword from "./components/admin/ForgottenPassword";
import PasswordReset from "./components/admin/PasswordReset";
import BillingReport from "./components/admin/BillingReport";
import Invitations from "./components/admin/Invitations";
import ProtectedRoute from "./components/common/ProtectedRoute";
import Dashboard from "./components/user-space/Dashboard";
import Content from "./components/user-space/Content";

export default function App() {
  const [apiState, setApiState] = useApiState(true);
  const [appInfo, setAppInfo] = useState<IAppInfo | null>(null);

  const getAppInfo = useCallback(async () => {
    try {
      const appInfo = await api.getAppInfo(setApiState);
      setAppInfo(appInfo);
    } catch (e) {
    }
  }, [setApiState]);

  useEffect(() => {
    getAppInfo();
  }, [getAppInfo]);

  function logoutHandler() {
    if (appInfo !== null)
      api.logout(setApiState).then(
        (logoutResponse: ILogoutResponse) => {
          redirectTo(logoutResponse.redirect);
          setAppInfo(null);
        },
        (e: any) => {
          getAppInfo();
        }
      );
  }

  return (
    <>
      <Loading isLoading={apiState.isLoading}>
        {!!apiState.errorMessage && !apiState.isUnauthenticated ? (
          <ErrorNotification errorMessage={apiState.errorMessage} />
        ) : (
          <>
            {appInfo !== null &&
              <BrowserRouter basename="/">
                <Routes>
                  <Route path="/*" element={
                    <Layout isAdminLayout={false} appInfo={appInfo} userInfo={appInfo!.userInfo ?? null} onLogout={logoutHandler}>
                      <ProtectedRoute userInfo={appInfo!.userInfo ?? null}>
                        <Dashboard userInfo={appInfo!.userInfo!} />
                      </ProtectedRoute>
                    </Layout>
                  } />

                  <Route path="/content" element={
                    <Layout isAdminLayout={false} appInfo={appInfo} userInfo={appInfo!.userInfo ?? null} onLogout={logoutHandler}>
                      <ProtectedRoute userInfo={appInfo!.userInfo ?? null}>
                        <Content
                          appInfo={appInfo}
                          userInfo={appInfo!.userInfo!} />
                      </ProtectedRoute>
                    </Layout>
                  } />

                  <Route path="/admin/*" element={
                    <Layout isAdminLayout={true} appInfo={appInfo} userInfo={appInfo!.userInfo ?? null} onLogout={logoutHandler}>
                      <Routes>
                        <Route index element={
                          <ProtectedRoute userInfo={appInfo!.userInfo ?? null}>
                            <Home />
                          </ProtectedRoute>
                        } />
                        <Route path="register" element={<Register />} />
                        <Route
                          path="invitations"
                          element={
                            <ProtectedRoute
                              userInfo={appInfo!.userInfo ?? null}
                              requiredPermissions={[
                                { permission: "partner_organization_read" },
                                { permission: "invitation_read_of_allowed_partner_organizations" },
                              ]}
                            >
                              <Invitations userInfo={appInfo!.userInfo!} />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="access-tokens"
                          element={
                            <ProtectedRoute userInfo={appInfo!.userInfo ?? null} requiredPermissions={[
                              { permission: "partner_organization_update" }
                            ]}>
                              <PartnerOrganizationManagement />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="billing-report"
                          element={
                            <ProtectedRoute userInfo={appInfo!.userInfo ?? null} requiredPermissions={[
                              { permission: "cohort_write" }
                            ]}>
                              <BillingReport />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="cohorts/*"
                          element={
                            <ProtectedRoute userInfo={appInfo!.userInfo ?? null} requiredAnyPermissions={[
                              { permission: "cohort_read" },
                              { permission: "cohort_write" }
                            ]} >
                              <CohortManagement userInfo={appInfo!.userInfo!} />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="password"
                          element={
                            <ProtectedRoute userInfo={appInfo!.userInfo ?? null}>
                              <PasswordChange />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="forgotten-password"
                          element={
                            <ForgottenPassword />
                          }
                        />
                        <Route
                          path="password-reset"
                          element={
                            <PasswordReset />
                          }
                        />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </Layout>
                  } />

                  <Route path="*" element={<NotFound />} />
                </Routes>
              </BrowserRouter>
            }
          </>)}
      </Loading>
    </>
  );
}
