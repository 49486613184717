import {
  FormEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import useApiState, { api } from "../../hooks/use-api-state";
import useInput from "../../hooks/use-input";
import ErrorNotification from "../common/ErrorNotification";
import PasswordInput from "../common/PaswordInput";
import Loading from "../common/Loading";
import styles from "./Register.module.css";
import { IRegistrationUserInfo } from "../../messages/IResponse";


function RegisterPage(props: {
  registrationToken: string;
  initialErrorMessage: string;
}): ReactElement {
  const [apiState, setApiState] = useApiState(false);
  const [registrationUserInfo, setRegistrationUserInfo] = useState<IRegistrationUserInfo| null>(null);
  const [registered, setRegistered] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const passwordInput = useInput(
    "",
    (value: string): boolean => {
      return value.length >= 9;
    },
    "cy__err"
  );
  const passwordRepeatInput = useInput(
    "",
    (value: string): boolean => {
      return passwordInput.value === value;
    },
    "cy__err"
  );
  const fullnameInput = useInput(
    "",
    (value: string): boolean => {
      return value.length > 0;
    },
    "cy__err"
  );


  async function passwordBlurHandler(
    event:
      | React.FormEvent<HTMLInputElement | HTMLSelectElement>
      | React.FocusEvent<HTMLTextAreaElement>
      | React.FormEvent<HTMLSelectElement>
  ) {
    apiState.errorMessage = "";
    if (registrationUserInfo !== null)
      api.checkPassword(
        passwordInput.value,
        {
          registrationToken: props.registrationToken,
          setErrorMessage: setPasswordErrorMessage
        }
      ).then((ret) => { });
    passwordInput.inputBlurHandler(event);
  }


  async function submitHandler(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (registrationUserInfo !== null) {
      const resp = await api.register(
        setApiState,
        props.registrationToken,
        fullnameInput.value,
        passwordInput.value
      );
      if (resp !== null) {
        if (resp.status === "OK") {
          setPasswordErrorMessage("");
          setRegistered(true);
        } else if (resp.status === "WEAK") {
          if (resp.message !== null) {
            setPasswordErrorMessage(resp.message);
          } else {
            setPasswordErrorMessage("The password is too weak");
          }
        } else {
          setApiState({
            isLoading: false,
            isUnauthenticated: false,
            errorMessage: "Unkonwn error",
          });
        }
      }
    }
  }

  const passwordRepeatErrorClass = passwordRepeatInput.hasError
    ? "cy__err"
    : "";
  const passwordRepeatErrorMessage = passwordRepeatInput.hasError
    ? "The passwords do not match"
    : "";

  const fullnameErrorClass = fullnameInput.hasError ? "cy__err" : "";
  const fullnameErrorMessage = fullnameInput.hasError
    ? "Please enter your full name here."
    : "";

  const fetchUserInfo = useCallback(async () => {
    try {
      let receviedUserInfo = await api.getUserInfoWithRegistrationToken(
        setApiState,
        props.registrationToken
      );
      if (receviedUserInfo !== null) {
        setRegistrationUserInfo(receviedUserInfo);
      }
    } catch (e: any) { }
  }, [props.registrationToken, setApiState]);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  if (props.initialErrorMessage !== "") {
    apiState.errorMessage = props.initialErrorMessage;
  }

  return (
    <>
      <Loading isLoading={apiState.isLoading}>
        {!!apiState.errorMessage ? (
          <>
            <div className="cy__login">
              <div className="login__flex">
                <ErrorNotification errorMessage={apiState.errorMessage} />
                <p>If you are in doubt or want to register again, please click <a href="mailto: delivery@cydrill.com?subject=Please register my email address again">here</a> to contact us.</p>
                <br />
                <button
                  className="btn wide secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = '/';
                  }}>
                  Back
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="cy__login">
            <div className="login__flex">

              {!registered ? (
                <h1>Register your account</h1>
              ) : (
                <h1 className="cy__succ">Successfully registered</h1>
              )}

              <form onSubmit={submitHandler} autoComplete="off">

                <h3>{registrationUserInfo?.email ?? ""}</h3>

                <div>
                  <label htmlFor="name">Full name</label>
                  <input
                    id="name"
                    type="name"
                    className={`${fullnameErrorClass}`}
                    value={fullnameInput.value}
                    onChange={fullnameInput.valueChangeHandler}
                    onBlur={fullnameInput.inputBlurHandler}
                    disabled={registered || props.initialErrorMessage !== ""}
                  />
                  {!!fullnameErrorMessage && (
                    <p className="cy__err">{fullnameErrorMessage}</p>
                  )}
                </div>

                {!registered && (
                  <>
                    <div className={styles.message_div}>
                      <p>
                        Please, be aware that the full name will be present on the
                        certificate you will receive for accomplishing tasks
                      </p>
                    </div>
                    <PasswordInput
                      label="Pick a password"
                      name="password"
                      disabled={props.initialErrorMessage !== ""}
                      className={`${passwordErrorMessage ? 'cy__err' : ''}`}
                      autoComplete="new-password"
                      value={passwordInput.value}
                      onChange={passwordInput.valueChangeHandler}
                      onBlur={passwordBlurHandler}
                    />
                    {!!passwordErrorMessage && (
                      <p className="cy__err">{passwordErrorMessage}</p>
                    )}

                    <PasswordInput
                      label="Repeat your password"
                      name="password-repeated"
                      disabled={props.initialErrorMessage !== ""}
                      className={`${passwordRepeatErrorClass}`}
                      autoComplete="new-password"
                      value={passwordRepeatInput.value}
                      onChange={passwordRepeatInput.valueChangeHandler}
                      onBlur={passwordRepeatInput.inputBlurHandler}
                    />

                    {!passwordErrorMessage && !!passwordRepeatErrorMessage && (
                      <p className="cy__err">{passwordRepeatErrorMessage}</p>
                    )}

                    <button
                      type="submit"
                      className="btn wide primary"
                      disabled={
                        !(props.initialErrorMessage === "" &&
                          fullnameInput.isValid &&
                          passwordErrorMessage === "" &&
                          passwordRepeatInput.isValid)
                      }
                    >
                      Register
                    </button>
                  </>
                )}

              </form>
              <br />
              <button
                className="btn wide secondary"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/';
                }}>
                Back
              </button>
            </div>

          </div>
        )}

      </Loading>
    </>
  );
}


export default function Register(): ReactElement {
  const [searchParams] = useSearchParams();
  const registrationToken = searchParams.get("registration-token") ?? "";
  let initialErrorMessage = "";

  if (registrationToken === "") {
    initialErrorMessage = "Registration token is missing";
  }

  return (
    <>
      <RegisterPage registrationToken={registrationToken} initialErrorMessage={initialErrorMessage} />
    </>
  );
}
