import { ReactElement } from "react";
import { IUserInfo } from "../../messages/IAppInfo";
import { hasPermission } from "../common/ProtectedRoute";
import { NavLink } from "react-router-dom";


export default function NavigationItems(props: {
  userInfo: IUserInfo | null;
}): ReactElement {
  function getNavLinkClass(props: { isActive: boolean }): string {
    if (props.isActive) {
      return "active_page active";
    } else {
      return "active_page";
    }
  }

  if (props.userInfo !== null) {
    return (
      <>
        <li>
          {props.userInfo.hasCourse && (
            <NavLink className={getNavLinkClass} to="/content">
              Courses
            </NavLink>
          )}
        </li>
        <li>
          {props.userInfo.hasVm && (
            <a className="active_page" href="/labs/vms">
              Labs
            </a>
          )}
        </li>
        <li>
          {props.userInfo.hasAssessment && (
            <a className="active_page" href="/assessments">
              Assessments
            </a>
          )}
        </li>
        <li>
          <a className="active_page" href="/rewards">
            My progress
          </a>
        </li>
        <li>
          {hasPermission(props.userInfo, [
            { permission: "cohort_read_rewards" }
          ]) && (
              <a className="active_page" href="/dashboard">
                Cohort dashboard
              </a>
            )}
        </li>
      </>
    );
  } else {
    return <></>;
  }
}
